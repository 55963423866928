define('uti-ui/controllers/settings/subscription', ['exports'], function (exports) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Controller.extend({

      setInfo: function () {
         var that = this;
         var keys = this.get('informer.keys');
         var rooms = this.get('roomsLength');
         var days = Math.floor(keys / rooms);
         var date = this.get('informer.freeMonth') ? moment().add('days', days + 30).format('LL') : moment().add('days', days).format('LL');
         this.set('date', date);
         this.set('days', days);
      }.observes('informer,informer.keys,roomsLength')

   });
});