define('uti-ui/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service('session'),
        alerts: Ember.inject.service(),
        inf: Ember.inject.service('informer'),

        isDebtorsRoute: function () {}.property('currentPath'),

        getInformer: function () {
            this.get('inf').getInformer();
        }.observes('session.data.authenticated.user.informer'),

        valueObserver2: function () {
            var that = this;
            this.set('informer', that.get('inf.informer'));
        }.observes('inf.informer'),

        history: [],

        hasHistory: function () {
            return this.get('history.length') > 1;
        }.property('history.length'),

        watchHistory: function () {
            this.get('history').pushObject(this.get('currentPath'));
        }.observes('currentPath'),

        actions: {

            //выход
            invalidateSession: function invalidateSession() {
                this.get('session').invalidate();
            },


            //поиск по клиентам
            searchClient: function searchClient(term) {
                if (term.length < 2) {
                    return [];
                }
                return this.store.query('client', {
                    or: [{ 'title': { "contains": term } }, { 'telephone': { "contains": term.replace(/[^0-9]/gim, '') } }, { 'email': { "contains": term } }]
                });
            },


            //поиск по контрагентам
            searchContractor: function searchContractor(term) {
                if (term.length < 2) {
                    return [];
                }
                return this.store.query('contractor', {
                    or: [{ 'title': { "contains": term } }, { 'inn': { "contains": term } }]
                });
            }
        }
    });
});