define('uti-ui/models/profile', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_emberValidations.default, {

        createdAt: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        timeZone: _emberData.default.attr('string'),
        smsName: _emberData.default.attr('string'),
        smsSecretKey: _emberData.default.attr('string'),
        startTime: _emberData.default.attr('string'),
        endTime: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        vk: _emberData.default.attr('string'),
        site: _emberData.default.attr('string'),
        currency: _emberData.default.attr('string'),
        startView: _emberData.default.attr('string'),

        openWidgetYa: _emberData.default.attr('string'),
        isEmailReq: _emberData.default.attr('boolean'),
        openWidgetGa: _emberData.default.attr('string'),
        yaCounter: _emberData.default.attr('string'),

        //оповещения
        //visits: DS.hasMany('visit', { async: true }), //неподтвержденные визиты
        //updates: DS.hasMany('update', { async: true }), // обновления системы

        //начальные настройки системы
        needSettings: _emberData.default.attr('boolean', { defaultValue: false }),
        freeMonth: _emberData.default.attr('boolean', { defaultValue: false }),
        includeEmpty: _emberData.default.attr('boolean', { defaultValue: false }),

        //счетчики
        visitsNew: _emberData.default.attr('number'),
        visitsCount: _emberData.default.attr('number'),
        receiptCount: _emberData.default.attr('number'),
        movingCount: _emberData.default.attr('number'),
        expenseCount: _emberData.default.attr('number'),
        telegramToken: _emberData.default.attr('number'),

        //баланс
        keys: _emberData.default.attr('number'),
        sms: _emberData.default.attr('number'),

        //оповещения
        telToSms: _emberData.default.attr('phone'),
        email: _emberData.default.attr('string'),
        isMailNewVisits: _emberData.default.attr('boolean'), //оповещения на почту о новых записях 
        isNewVisitAdmin: _emberData.default.attr('boolean'), //оповещение на резервный номер о новых запиях 
        isNewVisitClientInt: _emberData.default.attr('boolean'), //оповещение для клиента запись через виджет
        isNewVisitClientSite: _emberData.default.attr('boolean'), //оповещение для клиента запись через сайт
        isChangeVisitAdmin: _emberData.default.attr('boolean'), //оповещение на резервный номер об изменении времени визита 
        isChangeVisitClient: _emberData.default.attr('boolean'), //оповещение клиенту об изменении времени визита 
        isSmsDelAdm: _emberData.default.attr('boolean'), //оповещение на резевный номер об отмене броней
        isSmsDelClient: _emberData.default.attr('boolean'), //оповещение клиенту об отмене брони
        isRemember: _emberData.default.attr('boolean'),
        componentName: 'settings.first-settings',

        textNewVisitAdmin: _emberData.default.attr('string', { defaultValue: 'Новая бронь! #title, #date' }), //оповещения для админа интеграция 
        textNewVisitClient: _emberData.default.attr('string', { defaultValue: 'Вы успешно записаны на квест #title, #date' }), //оповещение для клиента запись через виджет
        textSmsDelClient: _emberData.default.attr('string', { defaultValue: 'Отмена брони #title, #date!' }), //отмена визита админу
        textSmsDelAdm: _emberData.default.attr('string', { defaultValue: 'Отмена брони #title, #date!' }), //отмена визита админу
        textRemember: _emberData.default.attr('string', { defaultValue: 'Ждем Вас на квесте #title через 2 часа' }),
        textChangeClient: _emberData.default.attr('string', { defaultValue: 'Бронь на квест #title #date перенесена на #newDate' }),
        textChangeAdmin: _emberData.default.attr('string', { defaultValue: 'Бронь на квест #title #date перенесена на #newDate' }),

        didLoad: function didLoad() {
            var self = this;
            setInterval(function () {
                self.reload();
            }, 5 * 60 * 1000); //every 5 minutes
        }
    });
});