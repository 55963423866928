define('uti-ui/components/form-to-validate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'div',
		classNames: ['has-error'],
		layoutName: 'components/form-to-validate',
		classNameBindings: ['isActive'],
		isActive: function () {
			return this.get('error') && this.get('error') != '' ? '' : 'hide';
		}.property('error')
	});
});