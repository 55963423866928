define('uti-ui/controllers/clients', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ajax: Ember.inject.service(),
        informer: Ember.inject.service(),
        disabled: false,

        actions: {
            cancelQueries: function cancelQueries() {
                var that = this;
                that.set('title', null);
                that.set('room', null);
                that.set('roomType', null);
                that.set('from', null);
                that.set('to', null);
                that.set('refresh', true);
                that.set('params', false);
                that.set('disabled', false);
                Ember.run.next(function () {
                    that.set('refresh', false);
                });
            },
            showSearch: function showSearch() {
                this.set('isShowSearch', true);
            },
            getSmsForm: function getSmsForm() {
                var that = this;
                var m = this.store.createRecord('sms-send', {
                    clients: that.get('params.id'),
                    clientsCount: that.get('params.id.length')
                });
                this.send('showModal', 'modals/sms', m);
            },
            findQueries: function findQueries() {

                if (this.get('disabled')) return false;
                this.set('disabled', true);

                var that = this;
                if (that.get('title') && that.get('title').length < 4) {
                    that.set('disabled', false);
                    return alert('Не менее 4 символов в строке поиска');
                }
                var q = {};

                if (that.get('roomType.type')) {
                    q.room = that.get('room.id');
                    q.type = that.get('roomType.type');
                }

                if (that.get('title')) {
                    q.title = that.get('title');
                    var f = q.title.charAt(0);
                    if (f == '7' || f == '8' || f == '+') q.title = q.title.replace(/[^0-9]/gim, '').slice(1);
                };

                var to = that.get('to'),
                    from = that.get('from');

                if (to) q.to = moment.utc(to).add(1, 'days').valueOf();
                if (from) q.from = moment.utc(from).valueOf();

                q = $.param(q);

                that.get('ajax').request('/clients/findByQueries?' + q).then(function (rsp) {
                    that.set('params', rsp);
                    that.set('refresh', true);
                    that.set('recordsLength', rsp.id.length);
                    Ember.run.next(function () {
                        that.set('refresh', false);
                        that.set('isShowSearch', false);
                        that.set('disabled', false);
                    });
                });
            },
            getExport: function getExport() {
                var that = this;
                if (that.get('disabled')) return false;
                that.set('disabled', true);

                that.get('ajax').request('/supports/getClients', {
                    method: 'POST',
                    data: that.get('params')
                }).then(function (rsp) {
                    var link = document.createElement('a');
                    link.setAttribute('href', rsp.link);
                    link.setAttribute('download', 'download');
                    onload = link.click();
                    that.set('disabled', false);
                });
            }
        }
    });
});