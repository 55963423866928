define('uti-ui/router', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('settings', function () {
            this.route('profile');
            this.route('subscription', function () {
                this.route('new-pay');
                this.route('payments');
            });

            this.route('items');
            this.route('cashbox');
            this.route('contractors');

            this.route('salary-scheme');

            this.route('questrooms', function () {
                this.route('new');
                this.route('edit', { path: ":room_id" });
            });
            this.route('integration', function () {
                this.route('social');
                this.route('site');
                this.route('constructor');
                this.route('room');
                this.route('agregators');
            });
            this.route('partners');
            this.route('discounts', function () {
                this.route('certificates');
                this.route('promocodes');
            });
            this.route('clients-tags');
            this.route('agregator');
        });
        this.route('support', function () {
            this.route('chat', function () {
                this.route('view', { path: ":chat_id" });
            });
            this.route('question', { path: ":faq_id" }, function () {});
            this.route('updates');
        });
        this.route('login');
        this.route('404', { path: '/*path' });

        this.route('finance');

        this.route('stock', function () {
            this.route('stocks');
            this.route('units');
            this.route('goods');

            this.route('moving', function () {
                this.route('all');
                this.route('movement', function () {
                    this.route('new');
                    this.route('edit', { path: ":id" });
                });
                this.route('cancel', function () {
                    this.route('new');
                    this.route('edit', { path: ":id" });
                });
                this.route('income', function () {
                    this.route('edit', { path: ":id" });
                    this.route('new');
                });
            });
            this.route('residues');
        });

        this.route('visits', function () {
            this.route('new');
            this.route('edit', { path: ":visit_id" });
            this.route('list');
            this.route('calendar');
        });

        this.route('users', function () {
            this.route('new');
            this.route('edit', { path: ":employee_id" });
            this.route('schedules');
            this.route('salary');
        });

        this.route('clients');
        this.route('403');
        this.route('certificates');
        this.route('sms', function () {
            this.route('sms-settings');
            this.route('sms-balance');
            this.route('sms-sendings');
            this.route('reports');
            this.route('sms-reports');
        });
        this.route('statistic');
        this.route('reset-password');
        this.route('registration');
        this.route('chat');
    });

    exports.default = Router;
});