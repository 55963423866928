define('uti-ui/routes/settings/questrooms/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),
        alerts: Ember.inject.service(),

        model: function model() {
            return this.get('informer.informer');
        },

        setupController: function setupController(controller, model) {
            var adr = this.get('store').peekAll('adr').get('firstObject');
            var q = this.store.createRecord('questroom', {
                telephone: model.get('telephone'),
                adress: adr
            });

            controller.set('model', q);
        },

        actions: {
            willTransition: function willTransition(transition) {
                var model = this.controllerFor('settings.questrooms.new').get('model');
                this.get('alerts').transition(transition, model);
            }
        }

    });
});