define('uti-ui/routes/settings/integration/social', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),

        styles: Ember.A([{ title: 'Темный', desc: 'darkStyle' }, { title: 'Светлый', desc: 'lightStyle' }]),

        setupController: function setupController(controller, model) {
            controller.set('model', this.get('informer.informer'));
            controller.set('styles', this.get('styles'));
            controller.set('style', this.get('styles.firstObject'));
        }
    });
});