define("uti-ui/components/init-component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			//$(".phone").mask(this.get('i18n').t('mask').string);
			$(".time").mask("99:99");
		}
	});
});