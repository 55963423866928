define('uti-ui/controllers/settings/agregator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    month: { m: moment(new Date()).format('MMMM'), c: moment(new Date()).format('MM') },
    year: moment(new Date()).format('YYYY'),
    fullPeriod: Ember.computed('month,year', function () {
      return this.get('month.m') + ' ' + this.get('year');
    }),

    m: Ember.A([{ m: 'Январь', c: '01' }, { m: 'Февраль', c: '02' }, { m: 'Март', c: '03' }, { m: 'Апрель', c: '04' }, { m: 'Май', c: '05' }, { m: 'Июнь', c: '06' }, { m: 'Июль', c: '07' }, { m: 'Август', c: '08' }, { m: 'Сентябрь', c: '09' }, { m: 'Октябрь', c: '10' }, { m: 'Ноябрь', c: '11' }, { m: 'Декабрь', c: '12' }]),
    y: Ember.A(['2015', '2016', '2017']),

    actions: {
      getInfo: function getInfo() {
        var that = this;
        var salaryPeriod = that.get('year') + '-' + that.get('month.c') + '-01';
        this.get('ajax').request('questrooms/getAgregatorInfo?period=' + salaryPeriod + '&profile=' + that.get("session.data.authenticated.user.profile")).then(function (data) {
          data.discountsSum = data.discounts * 500;
          that.set('info', data);
        });
      },
      pay: function pay() {
        var that = this;
        var salaryPeriod = that.get('year') + '-' + that.get('month.c') + '-01';

        var randomInteger = function randomInteger(min, max) {
          return Math.round(min + Math.random() * (max - min));
        };
        var amount = that.get('info.toPay');
        if (!amount) return toastr.error('Укажите сумму к оплате', 'Ошибка');
        var orderId = that.get("session.data.authenticated.user.profile") + randomInteger(11111, 99999);

        function makePayment(amount, orderId) {
          var params = {
            TerminalKey: "1481623458153",
            Amount: +amount * 100,
            OrderId: orderId
          };
          doPay(params);
        };

        var newPay = this.store.createRecord('payment', {
          transaction: orderId,
          period: salaryPeriod,
          amount: +amount,
          currency: 'РУБ',
          isConfirmed: false,
          type: 'agregator'
        });

        newPay.save().then(function () {
          makePayment(amount, orderId);
        });
      }
    }
  });
});