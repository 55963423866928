define('uti-ui/models/client', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    title: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('date'),
    telephone: _emberData.default.attr('phone'),
    vk: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    img: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),

    isSms: _emberData.default.attr('boolean', { defaultValue: true }),
    isEmail: _emberData.default.attr('boolean', { defaultValue: true }),
    isDelete: _emberData.default.attr('boolean', { defaultValue: false }),

    tagsList: _emberData.default.hasMany('clienttag', { async: true }),

    outlet: 'modals.left-modal',
    componentName: 'clients-form',
    validations: {
      title: { presence: true },
      telephone: { presence: true }
    }
  });
});