define('uti-ui/components/settings/sertificate-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),

		didInsertElement: function didInsertElement() {
			var _this = this;

			this.get('store').query('visit', {
				certificate: this.get('model.id')
			}).then(function (v) {
				return _this.set('visits', v);
			});
		},

		actions: {
			save: function save(model) {
				model.set('code', model.get('code').toUpperCase());
				this.sendAction('save', model, 'settings/discounts/certificates');
			},
			deleteOutlet: function deleteOutlet(model) {
				this.sendAction('deleteOutlet', model, 'settings/discounts/certificates');
			}
		}
	});
});