define('uti-ui/services/ajax', ['exports', 'ember-ajax/services/ajax', 'uti-ui/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({

    host: _environment.default.host,
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),

    headers: Ember.computed('session.data.authenticated.access_token', 'host', {
      get: function get() {
        var headers = {};
        var authToken = "Bearer " + this.get('session.data.authenticated.access_token');
        if (authToken) {
          headers["Authorization"] = authToken;
        }
        headers['Accept-Language'] = this.get('session.data.authenticated.locale') || _environment.default.lang;
        return headers;
      }
    })
  });
});