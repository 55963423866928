define('uti-ui/controllers/visits', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    list: Ember.inject.controller('visits.list'),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    from: null,
    to: null,
    room: null,
    usr: null,
    comand: null,
    user: null,
    st: null,
    telephone: null,
    comment: null,
    short: null,
    author: null,
    createFrom: null,
    createTo: null,

    actions: {
      showSearch: function showSearch() {
        this.set('isShowSearch', true);
      },
      cancelQueries: function cancelQueries() {
        var that = this;

        that.setProperties({
          to: null,
          from: null,
          room: null,
          usr: null,
          comand: null,
          disabled: false,
          user: null,
          st: null,
          telephone: null,
          comment: null,
          short: null,
          author: null,
          createFrom: null,
          createTo: null
        });

        that.get('list').set('params', null);
        that.set('length', null);
        that.send('refreshList');
      },
      findQueries: function findQueries() {
        var that = this;
        if (that.get('disabled')) return false;

        that.transitionToRoute('visits.list');
        var params = that.get('params');

        var queries = {
          manager: that.get('user.title') || null,
          author: that.get('usr.title') || null,
          room: that.get('room.id') || null,
          profile: that.get('session.data.authenticated.user.profile') || null,
          telephone: that.get('telephone') || null,
          short: that.get('author.short') || null,
          comment: that.get('comment') || null,
          comand: that.get('comand') || null,
          status: that.get('st') !== null ? that.get('st') : null
        };

        var length = -1,
            to = that.get('to') || null,
            from = that.get('from') || null,
            cStart = that.get('createFrom') ? moment.utc(that.get('createFrom')).valueOf() : null,
            cEnd = that.get('createTo') ? moment.utc(that.get('createTo')).add(1, 'days').valueOf() : null;

        if (to || from) {
          queries.date = {};
          if (to) queries.date['<='] = moment(new Date(to + ' UTC')).utc().add(1, 'days').format();
          if (from) queries.date['>='] = moment(new Date(from + ' UTC')).utc().format();
        }

        if (cStart || cEnd) {
          queries.createdAt = {};
          if (cStart) queries.createdAt['>='] = cStart;
          if (cEnd) queries.createdAt['<='] = cEnd;
        }

        for (var key in queries) {
          if (queries[key] === null) {
            delete queries[key];
            continue;
          }
          length++;
        }

        that.set('length', length);

        var params = length ? { where: queries } : null;
        that.get('list').set('params', params);
        that.send('refreshList');

        if (length) {
          that.set('disabled', true);
          that.get('ajax').request('/visits/findByQueries?' + $.param(queries)).then(function (rsp) {
            that.set('recordsLength', rsp);
            that.set('disabled', false);
          });
        }
      },
      refreshList: function refreshList() {
        var that = this;
        that.get('list').set('refresh', true);
        Ember.run.next(function () {
          that.get('list').set('refresh', false);
          that.set('isShowSearch', false);
        });
      },
      getExport: function getExport() {
        var that = this;
        that.get('ajax').request('/supports/getVisits?' + $.param(that.get('list').get('params'))).then(function (rsp) {
          var link = document.createElement('a');
          link.setAttribute('href', rsp.link);
          link.setAttribute('download', 'download');
          onload = link.click();
        });
      }
    }
  });
});