define('uti-ui/components/selects/power-users', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var users = this.get('store').query('user', { isDelete: false });
            this.set('users', users);
        }
    });
});