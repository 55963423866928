define('uti-ui/routes/users/index', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		model: function model() {
			return Ember.A([{ label: '', valuePath: 'img', width: '60px', cellComponent: 'tables/image-cell' }, { label: this.get('i18n').t('new.name'), valuePath: 'title' }, { label: this.get('i18n').t('qr.asc'), valuePath: 'specialty' }, { label: this.get('i18n').t('l.phone'), valuePath: 'telephone' }, { label: this.get('i18n').t('new.dst'), align: 'center', valuePath: 'canUse', cellComponent: 'tables/true-false' }, { label: '', cellComponent: 'tables/table-link', width: '40px' }]);
		},

		actions: {
			goTo: function goTo(model) {
				this.transitionTo('users.edit', model);
			}
		}
	});
});