define('uti-ui/routes/settings/salary-scheme', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseProfile')) {
                this.transitionTo('403');
            }
        },

        model: function model() {
            return this.store.findAll('salaryscheme');
        },

        setupController: function setupController(controller, model) {
            controller.set('scheme', model);
            this.controllerFor('application').set('title', 'Схемы расчета зарплаты');
        }
    });
});