define('uti-ui/components/statistic/finance-line', ['exports'], function (exports) {
				'use strict';

				Object.defineProperty(exports, "__esModule", {
								value: true
				});
				exports.default = Ember.Component.extend({

								pieObserver: Ember.observer('model.graph', function () {
												this.update();
								}),

								didInsertElement: function didInsertElement() {
												this.update();
								},
								update: function update() {
												var series = this.get('model.graph');
												var labels = [];
												for (var i = 0; i < series.length; i++) {
																labels[i] = i + 1 + ' нед';
																series[i] = series[i] ? series[i] : 0;
												}

												var chart = new Chartist.Line('#finance-line' + this.get('model.id'), {
																labels: labels,
																series: [series]
												}, {
																showArea: true,
																height: 200,
																fullWidth: true,
																labelInterpolationFnc: Chartist.noop
												});

												chart.on('draw', function (data) {
																if (data.type === 'line' || data.type === 'area') {
																				data.element.animate({
																								d: {
																												begin: 2000 * data.index,
																												dur: 2000,
																												from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
																												to: data.path.clone().stringify(),
																												easing: Chartist.Svg.Easing.easeOutQuint
																								}
																				});
																}
												});
								}
				});
});