define('uti-ui/routes/settings/subscription/new-pay', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator', 'uti-ui/config/environment'], function (exports, _authenticatedRouteMixin, _loadingIndicator, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
		informer: Ember.inject.service(),
		session: Ember.inject.service(),

		model: function model() {
			var that = this;
			return $.ajax({
				headers: { "Authorization": "Bearer " + that.get("session.data.authenticated.access_token") },
				url: _environment.default.host + '/profiles/roomsCount',
				type: 'GET',
				success: function success(data) {
					return data;
				}
			});
		},

		setupController: function setupController(controller, model) {
			controller.set('roomsLength', model);
			controller.set('informer', this.get('informer.informer'));
			controller.set('period', 1);
		}
	});
});