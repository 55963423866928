define('uti-ui/models/schedule', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		date: _emberData.default.attr('string'),
		end: _emberData.default.attr('string'),
		start: _emberData.default.attr('string'),
		user: _emberData.default.attr('string'),
		profile: _emberData.default.attr('string')
	});
});