define('uti-ui/models/cashbox', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    startSumm: _emberData.default.attr('number'),
    type: _emberData.default.attr('number'),
    isDefault: _emberData.default.attr('boolean'),
    isNew: _emberData.default.attr('boolean'),
    isDelete: _emberData.default.attr('boolean'),
    sum: _emberData.default.attr('number'),

    validations: {
      title: { presence: true }
    }
  });
});