define('uti-ui/models/wstyle', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		bgColor: _emberData.default.attr('string', { defaultValue: 'rgb(21, 21, 21)' }),
		timeBg: _emberData.default.attr('string', { defaultValue: 'rgb(235, 197, 0)' }),
		timeBgHover: _emberData.default.attr('string', { defaultValue: 'rgb(235, 197, 0)' }),
		time: _emberData.default.attr('string', { defaultValue: '#222' }),
		textColor: _emberData.default.attr('string', { defaultValue: '#222' }),
		timeBorder: _emberData.default.attr('string', { defaultValue: 'rgb(212, 151, 0)' }),
		bussyBg: _emberData.default.attr('string', { defaultValue: 'rgba(90, 90, 90, 0.57)' }),
		bussy: _emberData.default.attr('string', { defaultValue: 'rgb(129, 129, 129)' }),
		bussyBorder: _emberData.default.attr('string', { defaultValue: 'rgba(0, 0, 0, 0.24)' }),
		dateBg: _emberData.default.attr('string', { defaultValue: 'rgba(255, 255, 255, 0.11)' }),
		date: _emberData.default.attr('string', { defaultValue: '#fff' }),
		dateH: _emberData.default.attr('string', { defaultValue: 'rgb(235, 197, 0)' }),
		price: _emberData.default.attr('string', { defaultValue: '#ccc' }),
		timeBorderStyle: _emberData.default.attr('string', { defaultValue: 'solid' }),
		radius: _emberData.default.attr('string', { defaultValue: 12 }),
		position: _emberData.default.attr('string', { defaultValue: 'left' }),
		isActive: _emberData.default.attr('boolean'),
		isButtonCustom: _emberData.default.attr('boolean', { defaultValue: false }),
		buttonText: _emberData.default.attr('string', { defaultValue: 'Забронировать' }),
		buttonTextColor: _emberData.default.attr('string', { defaultValue: 'rgb(255, 255, 255)' }),
		buttonColor: _emberData.default.attr('string', { defaultValue: 'rgb(62, 74, 90)' }),
		buttonWaves: _emberData.default.attr('string', { defaultValue: 'rgb(62, 74, 90)' }),
		isDelete: false
	});
});