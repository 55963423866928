define('uti-ui/routes/users/schedules', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		afterModel: function afterModel() {
			if (!this.get('session.data.authenticated.access.canUseUsers')) {
				this.transitionTo('users');
			}
		}
	});
});