define('uti-ui/models/sms-send', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    createdAt: _emberData.default.attr('date'),
    text: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    senders: _emberData.default.attr('number'),
    sendings: _emberData.default.attr('number'),
    textLength: _emberData.default.attr('number'),
    clientsCount: _emberData.default.attr('number'),
    sms: _emberData.default.hasMany('sms-report', { async: true }),
    clients: _emberData.default.attr('string'),
    formatDate: function () {
      return moment(this.get('createdAt')).locale('ru').calendar();
    }.property('createdAt'),

    outlet: function () {
      return 'modals.sms-report';
    }.property(),

    validations: {
      text: { presence: true },
      title: { presence: true }
    }
  });
});