define('uti-ui/routes/visits/edit', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

    model: function model(params) {
      return this.store.findRecord('visit', params.visit_id);
    },

    alerts: Ember.inject.service(),

    actions: {

      willTransition: function willTransition(transition) {
        var model = this.currentModel;
        this.get('alerts').transition(transition, model);
      },

      redirect: function redirect() {
        this.transitionTo('visits.list');
      }
    }

  });
});