define('uti-ui/components/settings/discount-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		actions: {
			save: function save(model) {
				this.sendAction('save', model, 'settings/discounts/index');
			},
			deleteOutlet: function deleteOutlet(model) {
				this.sendAction('deleteOutlet', model, 'settings/discounts/index');
			}
		}
	});
});