define('uti-ui/components/tables/chose-agent', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    computed = Ember.computed;
	exports.default = Ember.Component.extend({
		m: computed('row', function () {
			if (this.get('row.contractor.id')) {
				return this.get('row.contractor');
			};
			if (this.get('row.client.id')) {
				return this.get('row.client');
			};
			if (this.get('row.user.id')) {
				return this.get('row.user');
			};
		})
	});
});