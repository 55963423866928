define('uti-ui/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'uti-ui/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', {
        "Accept-Language": _environment.default.lang
      });
    },


    setHeaders: Ember.observer('session', function () {
      this.set('headers', {
        "Authorization": "Bearer " + this.get("session.data.authenticated.access_token"),
        "Accept-Language": this.get("session.data.authenticated.lang") || _environment.default.lang
      });
    }),

    authorizer: 'authorizer:oauth2',
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },

    coalesceFindRequests: true,
    host: _environment.default.host
  });
});