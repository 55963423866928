define('uti-ui/components/tables/date-simple', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    computed = Ember.computed;
	exports.default = Ember.Component.extend({
		date: computed('value', function () {
			return this.get('value') ? moment(this.get('value')).format('DD.MM.YYYY') : '-';
		})
	});
});