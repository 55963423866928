define('uti-ui/services/informer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		informer: null,
		store: Ember.inject.service(),
		session: Ember.inject.service(),
		i18n: Ember.inject.service(),

		init: function init() {
			this._super.apply(this, arguments);
		},
		getInformer: function getInformer() {
			var that = this,
			    id = this.get("session.data.authenticated.user.profile"),
			    store = this.get('store');
			if (id) {
				return store.findRecord('profile', id).then(function (i) {
					that.set('informer', i);

					var per = (new Date() - new Date(i.get('createdAt'))) / (24 * 60 * 60 * 1000);
					var freePer = i.get('freeMonth') ? 30 : 7;

					if (per > freePer && i.get('keys') < 9 && i.get('keys') >= 1) {
						toastr.warning('Ключики скоро закончатся', 'Внимание!', {
							timeOut: 1000000
						});
					};

					if (per > freePer && i.get('keys') < 1) {
						toastr.error('На счете закончились ключики', 'Внимание!', {
							timeOut: 1000000
						});
					};

					return that.get('informer');
				});
			} else {
				return false;
			}
		},
		isAccess: function isAccess() {
			return this.get('informer.keys') >= 0 ? true : false;
		},
		addCount: function addCount(string) {
			var informer = this.get('informer');
			informer.incrementProperty(string);
			informer.save();
		}
	});
});