define('uti-ui/routes/sms/sms-sendings', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
		model: function model() {
			return Ember.A([{ label: 'Дата', valuePath: 'formatDate' }, { label: 'Название', valuePath: 'title' }, { label: 'Текст смс', valuePath: 'text' }, { label: 'Длина смс', valuePath: 'textLength' }, { label: 'Доставлено', valuePath: 'sendings' }, { label: 'Отправлено', valuePath: 'senders', cellComponent: 'tables/custom-action' }]);
		},

		actions: {
			custom: function custom(model) {
				var i = model.get('outlet');
				var m = model.get('content') ? model.get('content') : model;
				this.send('showModal', i, m, '');
			}
		}
	});
});