define('uti-ui/models/imag', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		visit: _emberData.default.attr('string'),
		questroom: _emberData.default.attr('string'),
		img: _emberData.default.attr('string')
	});
});