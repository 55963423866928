define('uti-ui/routes/settings/contractors', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseProfile')) {
                this.transitionTo('403');
            }
        },

        model: function model() {
            return Ember.A([{ label: this.get('i18n').t('l.title'), valuePath: 'title' }, { label: this.get('i18n').t('l.phone'), valuePath: 'telephone' }, { label: this.get('i18n').t('l.email'), valuePath: 'email' }, { label: this.get('i18n').t('l.contact'), valuePath: 'contact' }, { label: this.get('i18n').t('l.inn'), valuePath: 'inn' }, { label: '', cellComponent: 'tables/table-link', width: '35px' }, { label: '', cellComponent: 'tables/delete-button', width: '40px' }]);
        }
    });
});