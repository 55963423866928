define('uti-ui/controllers/users/salary', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        session: Ember.inject.service('session'),
        month: { m: moment(new Date()).format('MMMM'), c: moment(new Date()).format('MM') },
        year: moment(new Date()).format('YYYY'),
        fullPeriod: Ember.computed('month,year', function () {
            return this.get('month.m') + ' ' + this.get('year');
        }),

        m: Ember.A([{ m: undefined.get('i18n').t('mo.jan'), c: '01' }, { m: undefined.get('i18n').t('mo.feb'), c: '02' }, { m: undefined.get('i18n').t('mo.mar'), c: '03' }, { m: undefined.get('i18n').t('mo.apr'), c: '04' }, { m: undefined.get('i18n').t('mo.may'), c: '05' }, { m: undefined.get('i18n').t('mo.jun'), c: '06' }, { m: undefined.get('i18n').t('mo.jule'), c: '07' }, { m: undefined.get('i18n').t('mo.aug'), c: '08' }, { m: undefined.get('i18n').t('mo.sep'), c: '09' }, { m: undefined.get('i18n').t('mo.oct'), c: '10' }, { m: undefined.get('i18n').t('mo.nov'), c: '11' }, { m: undefined.get('i18n').t('mo.des'), c: '12' }]),
        y: Ember.A(['2015', '2016', '2017', '2018']),

        actions: {
            getSalary: function getSalary() {
                var that = this;
                var salaryPeriod = that.get('year') + '-' + that.get('month.c') + '-01';
                this.store.query('salary', { salaryPeriod: salaryPeriod }).then(function (data) {
                    that.set('salary', data);
                });
            },


            newPay: function newPay(model) {
                var _this2 = this;

                var _this = this;
                if (!model.get('cashbox') || !model.get('tsum')) return alert('Choose cashbox and sum');
                this.store.query('financeitem', {
                    'sid': 2
                }).then(function (m) {
                    var row = _this.store.createRecord('transaction', {
                        type: m.get('firstObject'),
                        cashbox: model.get('cashbox'),
                        sum: model.get('tsum'),
                        user: model.get('user.id'),
                        outlet: 'modals/finance/expence',
                        title: _this2.get('i18n').t('qr.salCalc') + _this2.get('fullPeriod'),
                        author: _this.get("session.data.authenticated.user.fullName")
                    });
                    row.save().then(function () {
                        model.get('transactions').addObject(row);
                        model.incrementProperty('payed', +model.get('tsum'));
                        model.set('tsum', '');
                    });
                });
            }
        }
    });
});