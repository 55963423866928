define('uti-ui/routes/index', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		session: Ember.inject.service(),

		beforeModel: function beforeModel(transition) {

			if (this.get('session.data.authenticated.access_token')) {
				if (this.get('session.data.authenticated.access.canUseVisits')) {
					this.transitionTo('visits.list');
				} else {
					this.transitionTo('users.index');
				}
			} else {
				this.transitionTo('login');
			}
		}
	});
});