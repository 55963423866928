define('uti-ui/routes/sms/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		informer: Ember.inject.service(),
		session: Ember.inject.service(),

		beforeModel: function beforeModel() {
			if (!this.get('session.data.authenticated.access.canUseSms')) {
				this.transitionTo('403');
			} else {
				this.transitionTo('sms.sms-settings');
			}
		}
	});
});