define('uti-ui/components/parts/discount-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.get('store').findAll('discount').then(function (data) {
				return _this.set('data', data);
			});
		}
	});
});