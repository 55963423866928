define('uti-ui/routes/settings/clients-tags', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('session.data.authenticated.access.canUseProfile')) {
        this.transitionTo('403');
      }
    },

    model: function model() {
      return this.store.findAll('clienttag');
    }
  });
});