define('uti-ui/transforms/phone', ['exports', 'ember-data', 'uti-ui/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var length = void 0,
      pref = false;

  if (_environment.default.lang === 'ru') {
    length = 11;
    pref = '7';
  }

  if (_environment.default.lang === 'ua' || _environment.default.lang === 'eu') length = 12;

  var func = function func(v) {
    var phone = v ? '' + v.replace(/[^0-9]/gim, '') : false;
    if (phone && phone.charAt(0) === '8') phone = '7' + phone.slice(1);
    //var phone = v ? v.replace(/[^0-9]/gim, '') : false;
    // if (!length || !phone || phone.length !== length) return '';
    //var r = pref ? pref + phone.substr(1, phone.length - 1) : phone;
    return phone;
  };

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      //return serialized
      return func(serialized);
    },
    serialize: function serialize(deserialized) {
      //return deserialized
      return func(deserialized);
    }
  });
});