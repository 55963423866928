define('uti-ui/components/full-calendar', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var games = function games(num) {
    if (num > 4) return '';
    if (+num === 1) return 'a';
    if (num > 1 && num < 5) return 'ы';
  };

  exports.default = Ember.Component.extend({

    session: Ember.inject.service(),
    alerts: Ember.inject.service(),
    store: Ember.inject.service(),
    informer: Ember.inject.service(),
    ajax: Ember.inject.service(),
    chandeDate: Ember.observer('view', 'date', function () {
      this.getCalendar();
    }),

    didInsertElement: function didInsertElement() {
      this.getCalendar();
    },

    getCalendar: function getCalendar() {
      var store = this.get('store'),
          informer = this.get('informer.informer'),
          view = informer.get('startView') || 'Месяц',
          views = {
        'День': 'agendaDay',
        'Неделя': 'agendaWeek',
        'Месяц': 'month'
      },
          that = this;

      var goToRoom = function goToRoom(id) {
        that.sendAction('redirect', 'settings.questrooms.edit', id);
      };
      Ember.$('#calendar').fullCalendar({
        locale: that.get('i18n').t('lang'),
        allDayText: 'Заметки',
        nextDayThreshold: '06:00',
        lazyFetching: false,
        defaultView: that.get('view') || views[view],
        titleFormat: 'dddd, DD MMMM',
        minTime: informer.get('startTime') ? informer.get('startTime') + ':00' : '10:00:00',
        maxTime: informer.get('endTime') ? informer.get('endTime') + ':00' : '24:00:00',
        contentHeight: 'auto',
        slotDuration: '00:15:00',
        defaultDate: that.get('date') ? moment(that.get('date')) : moment(),
        nowIndicator: true,
        selectable: true,
        editable: true,
        slotLabelFormat: "HH:mm",
        timeFormat: "HH:mm",
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'agendaDay,agendaWeek,month'
        },

        resources: function resources(callback) {
          //var view = $('#calendar').fullCalendar('getView');
          //if (view !== 'agendaDay') return [];
          that.get('ajax').request('/questrooms/getRooms').then(function (resources) {
            callback(resources);
          });
        },

        events: function events(start, end, timezone, callback) {
          var fecha = $('#calendar').fullCalendar('getDate');
          var view = $('#calendar').fullCalendar('getView');

          that.get('ajax').request('/questrooms/getVisits?start=' + fecha.format('YYYY-MM-DD') + '&type=' + view.name + '&include=' + +informer.get('includeEmpty')).then(function (d) {

            callback(d);
          });
        },

        viewRender: function viewRender(view, element) {
          $(".fc-prev-button, .fc-next-button").prop('disabled', true).addClass('fc-state-disabled');
          that.set('date', view.start.format('YYYY-MM-DD'));
          that.set('view', view.name);
        },

        eventDrop: function eventDrop(event, delta, revertFunc) {
          if (!confirm('Вы уверены что хотите перенести время записи? Не знабудьте проверить цену визита')) return revertFunc();
          var dateEnd = event.end ? event.end.utc().format() : false;
          var notVisit = event.backgroundColor === '#f1f1f1' ? true : false;
          var path = '/visits/changeVisitInfo?room=' + event.resourceId + '&date=' + event.start.utc().format() + '&dateEnd=' + dateEnd + '&id=' + event.id + '&allDay=' + event.allDay + '&notVisit=' + notVisit;

          that.get('alerts').ajaxSend(path);
        },

        resourceRender: function resourceRender(resourceObj, labelTds, bodyTds) {
          labelTds.html(labelTds.text());
          labelTds.on('click', function () {
            goToRoom(resourceObj.id);
          });
        },

        // eventResize: function(event, dayDelta, minuteDelta, allDay, revertFunc) {
        //   var dateEnd = event.end ? event.end.utc().format() : false;
        //   var notVisit = event.backgroundColor === '#f1f1f1' ? true : false;

        //   var path = '/visits/changeVisitInfo?room=' + event.resourceId +
        //     '&date=' + event.start.utc().format() + '&dateEnd=' +
        //     dateEnd + '&id=' + event.id + '&allDay=' +
        //     event.allDay + '&notVisit=' + notVisit;

        //   that.get('alerts').ajaxSend(path);
        // },

        eventRender: function eventRender(event, element, view) {
          element.find('.fc-title').html(element.find('.fc-title').text());
          $(element).each(function () {
            $(this).attr('date-num', event.start.format('YYYY-MM-DD'));
          });
        },

        eventAfterAllRender: function eventAfterAllRender(view) {
          for (var cDay = view.start.clone(); cDay.isBefore(view.end); cDay.add(1, 'day')) {
            var dateNum = cDay.format('YYYY-MM-DD');
            var dayEl = $('.fc-day[data-date="' + dateNum + '"]');
            var eventCount = $('.visitBlock[date-num="' + dateNum + '"]').length;
            if (eventCount) {
              var html = '<span class="event-count"><i>' + eventCount + '</i>' + ' игр' + games(eventCount) + '</span>';
              dayEl.append(html);
            }
          }
          $(".fc-prev-button, .fc-next-button").prop('disabled', false).removeClass('fc-state-disabled');
        },

        eventClick: function eventClick(calEvent, jsEvent, view) {
          if (calEvent.backgroundColor === '#f1f1f1') {
            var temp = calEvent.allDay === true ? 'modals/calendar-comment' : 'modals/calendar-visit';
            store.findRecord('allday', calEvent.id).then(function (m) {
              that.send('showModal', m, false, temp);
            });
          } else {
            that.sendAction('redirect', 'visits.edit', calEvent.id);
          }
        },

        dayClick: function dayClick(date, jsEvent, view, resourceObj) {
          if (view.name !== 'month') {
            var temp = !date.hasTime() ? 'modals/calendar-comment' : 'modals/calendar-visit';
            that.send('showModal', false, date.utc().format(), temp);
          } else {
            $('#calendar').fullCalendar('gotoDate', date);
            $('#calendar').fullCalendar('changeView', 'agendaDay');
          }
        }
      });
    },


    actions: {
      showModal: function showModal(model, date, template) {
        model = model || this.get('store').createRecord('allday', {
          date: date
        });

        this.sendAction('showModal', template, model, false);
      }
    }
  });
});