define('uti-ui/components/search/visits-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    authors: Ember.A([{
      title: 'Виджет на сайте',
      short: 'www'
    }, {
      title: 'Виджет вконтакте',
      short: 'VK'
    }, {
      title: 'Администратор',
      short: 'Adm'
    }, {
      title: 'QuestMe',
      short: 'QM'
    }, {
      title: 'Мир квестов',
      short: 'MK'
    }, {
      title: 'Гильдия Квестов',
      short: 'GQ'
    }, {
      title: 'QR24',
      short: 'QR24'
    }, {
      title: 'Квестикс',
      short: 'KS'
    }, {
      title: 'Хочу Онлайн',
      short: 'HO'
    }, {
      title: 'Sarquest',
      short: 'sQ'
    }, {
      title: 'ExtraReality',
      short: 'ER'
    }, {
      title: 'Q-Room',
      short: 'QR'
    }, {
      title: 'QuestFinder',
      short: 'QF'
    }, {
      title: 'ТопКвестов',
      short: 'TK'
    }, {
      title: 'КвестГид',
      short: 'Kg'
    }, {
      title: 'QuestCompass',
      short: 'QC'
    }, {
      title: 'Тест Квест',
      short: 'TQ'
    }, {
      title: 'Квестики',
      short: 'Kv'
    }, {
      title: 'Квестам.ру',
      short: 'Kp'
    }, {
      title: 'Qimnata',
      short: 'Qi'
    }]),

    status: Ember.A(),

    didInsertElement: function didInsertElement() {
      var status = this.get('st');
      if (status) $('.status' + status).parent().addClass('active');
      var store = this.get('store');
      var _this = this;
      store.findAll('questroom').then(function (d) {
        _this.set('rooms', d);
      });
      store.findAll('user').then(function (d) {
        _this.set('users', d);
      });
      store.findAll('discount').then(function (d) {
        _this.set('bonuses', d);
      });
      this.set('status', [{
        title: this.get('i18n').t('statuses.notOrder'),
        id: 0
      }, {
        title: this.get('i18n').t('statuses.notOrder'),
        id: 1
      }, {
        title: this.get('i18n').t('statuses.canceled'), //'Отменен',
        id: 2
      }, {
        title: this.get('i18n').t('statuses.notCame'), //'Не пришли',
        id: 3
      }, {
        title: this.get('i18n').t('statuses.payed'), // 'Оплачен',
        id: 4
      }, {
        title: this.get('i18n').t('statuses.halfPayed'), //'Есть долг',
        id: 5
      }, {
        title: this.get('i18n').t('statuses.muchPayed'), //'Переплата',
        id: 6
      }, {
        title: this.get('i18n').t('statuses.notConfirm'), //'Не подтвержден',
        id: 10
      }]);
    },

    actions: {
      setStatus: function setStatus(m) {
        this.set('st', m.id);
        $('.status').removeClass('active');
        $('.status' + this.get('st')).parent().addClass('active');
      },
      hide: function hide() {
        this.set('isShowSearch', false);
      },
      showResults: function showResults() {
        this.sendAction('findQueries');
      }
    }
  });
});