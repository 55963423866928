define('uti-ui/components/tables/date-cell-utc', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		date: Ember.computed('value', function () {
			return this.get('value') ? moment.utc(this.get('value')).calendar() : '';
		})
	});
});