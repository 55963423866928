define('uti-ui/routes/chat', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		ajax: Ember.inject.service(),

		setupController: function setupController(controller, model) {
			var id = this.get('session.data.authenticated.user.id');
			this.get('ajax').request('chats/getChats/' + id).then(function (d) {
				d = d.map(function (i) {
					if (i.from.id !== id) i.current = i.from;
					if (i.to.id !== id) i.current = i.to;
					i.lastMessage.createdAt = moment(i.lastMessage.createdAt).format('DD MMM, HH:mm');
					return i;
				});
				controller.set('chats', d);
			});
		}
	});
});