define('uti-ui/models/questroomdateinterval', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    date: _emberData.default.attr('string'),
    start: _emberData.default.attr('string'),
    startUTC: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    room: _emberData.default.attr('string'),
    hidden: _emberData.default.attr('boolean', { defaultValue: false }),
    spPriceArr: _emberData.default.attr('array', { defaultValue: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }),

    discount: _emberData.default.belongsTo('discount', { async: true }),

    validations: {
      start: { time: true, presence: true },
      price: { presence: true }
    }
  });
});