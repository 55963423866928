define('uti-ui/controllers/modals/calendar-comment', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		init: function init() {
			var that = this;
			this.store.findAll('questroom').then(function (q) {
				that.set('rooms', q);
			});
		},


		actions: {
			destroy: function destroy(m) {
				var obj = [m.get('id')];
				$('#calendar').fullCalendar('removeEvents', obj);
				m.destroyRecord().then(function () {
					that.get('alerts').showAlert('success');
					$('.modal').modal('hide');
				}).catch(function (err) {
					that.get('alerts').showAlert('error');
				});
			}
		}

	});
});