define('uti-ui/models/sms-payment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    transaction: _emberData.default.attr('string'), //TransactionId
    amount: _emberData.default.attr('string'), //Amount
    currency: _emberData.default.attr('string'), //Currency
    card: _emberData.default.attr('string'), //CardFirstSix
    sms: _emberData.default.attr('string'),
    formatDate: function () {
      return moment(this.get('createdAt')).locale('ru').calendar();
    }.property('createdAt')
  });
});