define('uti-ui/components/finance-window', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberValidations.default, {

    validations: {
      //  'model.sum': {presence: {message: 'Поле обязательно для заполнения'}}
    },
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    informer: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var store = this.get('store');
      var _this = this;
      if (this.get('ftype') === 'Поступление денежных средств') {
        store.query('finance-item', { type: 1 }).then(function (type) {
          _this.set('items', type);
          _this.set('type', 'receiptCount');
        });
        var obj = {
          'model.sum': { presence: { message: 'Поле обязательно для заполнения' } }
        };
        this.set('validations', obj);
      }

      if (this.get('ftype') === 'Расход денежных средств') {
        store.query('finance-item', { type: 0 }).then(function (type) {
          _this.set('items', type);
          _this.set('type', 'expenseCount');
        });
      }

      if (this.get('ftype') === 'Перемещение денежных средств') {
        store.query('finance-item', { sid: 10 }).then(function (m) {
          _this.set('model.type', m.get('firstObject'));
          _this.set('type', 'movingCount');
        });
      }

      store.findAll('cashbox').then(function (type) {
        _this.set('cashbox', type);
      });
      store.findAll('user').then(function (type) {
        _this.set('users', type);
      });
    },

    actions: {
      ok: function ok() {
        var that = this;
        var model = this.get('model');
        var ftype = this.get('ftype');
        var type = this.get('type');
        model.set('title', ftype + ' №' + (that.get('informer').get(type) + 1));
        model.set('author', that.get("session.data.authenticated.user.fullName"));

        this.validate().then(function () {
          that.sendAction('ok', model, type);
          that.$('.modal').modal('hide');
        }).catch(function () {
          that.set('err', that.get('errors'));
          that.get('alerts').showAlert('warning');
        });
      }
    },
    show: function () {
      this.$('.modal').modal('show').on('hidden.bs.modal', function () {
        this.get('model').rollbackAttributes();
        this.sendAction('removeModal');
      }.bind(this));
    }.on('didInsertElement')
  });
});