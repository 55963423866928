define('uti-ui/components/last-visits', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		ajax: Ember.inject.service(),
		visitsLength: '',

		didInsertElement: function didInsertElement() {
			this.getLastVisits();
		},

		getLastVisits: function getLastVisits() {
			var _this = this;

			this.get('store').query('visit', {
				status: 10,
				limit: 10
			}).then(function (data) {
				_this.set('lastVisits', data.toArray());
				var l = data.toArray().length;
				_this.set('visitsLength', l ? l + '+' : '');
			});
		},


		actions: {
			acept: function acept(model, status) {
				var _this2 = this;

				this.get('ajax').request('questrooms/changeStatus?id=' + model.get('id') + '&status=' + status).then(function () {
					_this2.get('lastVisits').removeObject(model);
					if (_this2.get('lastVisits.length') < 4) _this2.getLastVisits();
				}).catch(function (_ref) {
					var response = _ref.response,
					    jqXHR = _ref.jqXHR,
					    payload = _ref.payload;
					return alert('Визит не подтвержден, попробуйте чуть позже');
				});
			},
			refresh: function refresh() {
				this.getLastVisits();
			},
			showModal: function showModal(template, model, modelName, id) {
				this.sendAction('showModal', template, model, modelName, id);
			}
		}
	});
});