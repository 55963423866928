define('uti-ui/controllers/reset-password', ['exports', 'ember-validations', 'uti-ui/config/environment'], function (exports, _emberValidations, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberValidations.default, {

        ajax: Ember.inject.service(),

        actions: {
            reset: function reset() {
                var that = this;
                var mail = this.get('identification');
                that.get('ajax').request('/auths/reset?email=' + mail).then(function (res) {
                    that.set('message', res.message);
                    that.set('err', false);
                }).catch(function (_ref) {
                    var res = _ref.res,
                        jqXHR = _ref.jqXHR,
                        payload = _ref.payload;

                    that.set('err', payload.message);
                    that.set('message', false);
                });
            }
        }
    });
});