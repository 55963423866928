define('uti-ui/models/support', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        createdAt: _emberData.default.attr('date'),
        body: _emberData.default.attr('string'),
        author: _emberData.default.belongsTo('user', { async: true }),
        isAnswer: _emberData.default.attr('boolean'),
        isWatch: _emberData.default.attr('boolean'),
        supportInform: _emberData.default.attr('string'),
        isDelete: _emberData.default.attr('boolean', { defaultValue: false }),
        supportCase: _emberData.default.belongsTo('support-case', { async: true }),
        formattedDate: function () {
            var date = this.get('createdAt');
            return moment(date).utc().format('L') + ' в ' + moment(date).utc().format('LT');
        }.property('createdAt')
    });
});