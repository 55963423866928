define('uti-ui/routes/settings/integration/constructor', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

    informer: Ember.inject.service(),

    allign: Ember.A(['left', 'right', 'center']),
    timeBorderStyle: Ember.A(['solid', 'dashed', 'dotted']),

    model: function model() {
      var that = this;
      //return that.store.createRecord('wstyle');
      return that.store.queryRecord('wstyle', { profile: that.get('informer.informer.id') }).then(function (m) {
        if (!!m) {
          return m;
        } else {
          return that.store.createRecord('wstyle');
        }
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('allign', this.get('allign'));
      controller.set('timeBorderStyle', this.get('timeBorderStyle'));
    },

    actions: {
      save: function save(m) {
        var _this = this;

        m.save().then(function () {
          _this.get('alerts').showAlert('success');
        });
      },
      setDefault: function setDefault(m) {
        m.setProperties({
          bgColor: 'rgb(21, 21, 21)',
          timeBg: 'rgb(235, 197, 0)',
          timeBgHover: 'rgb(235, 197, 0)',
          time: '#222',
          textColor: '#222',
          timeBorder: 'rgb(212, 151, 0)',
          bussyBg: 'rgba(90, 90, 90, 0.57)',
          bussy: 'rgb(129, 129, 129)',
          bussyBorder: 'rgba(0, 0, 0, 0.24)',
          dateBg: 'rgba(255, 255, 255, 0.11)',
          date: '#fff',
          dateH: 'rgb(235, 197, 0)',
          price: '#ccc',
          timeBorderStyle: 'solid',
          radius: 12,
          position: 'left',
          isButtonCustom: false,
          buttonText: 'Забронировать',
          buttonTextColor: 'rgb(255, 255, 255)',
          buttonColor: 'rgb(62, 74, 90)',
          buttonWaves: 'rgb(62, 74, 90)'
        });
      }
    }
  });
});