define('uti-ui/mixins/loading-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition() {
        this.send('removeModal');
        Ember.run.later(function () {
          $('#second').removeClass('showMenu');
          $('#second .bgNotif').fadeOut(100);
        }, 1000);
        return true;
      },
      error: function error() {
        return true;
      }
    }
  });
});