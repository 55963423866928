define('uti-ui/components/selects/power-rooms', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var rooms = this.get('store').peekAll('questroom');
            this.set('rooms', rooms.filterBy('isDelete', false).filterBy('isActive', true));
        }
    });
});