define('uti-ui/components/support-window', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, {

        validations: {
            question: { presence: true }
        },

        store: Ember.inject.service(),

        actions: {
            ask: function ask(model) {
                var store = this.get('store');
                var that = this;
                this.validate().then(function () {
                    if (model === undefined) {
                        model = store.createRecord('support-case', {
                            title: that.get('title')
                        });
                    }

                    model.save().then(function () {
                        var question = store.createRecord('support', {
                            body: that.get('question'),
                            isWatch: true,
                            supportCase: model.get('id')
                        });
                        model.get('messages').pushObject(question);
                        question.save().then(function () {
                            that.get('alerts').showAlert('success');
                            that.set('question', '');
                            that.set('title', '');
                        }).catch(function () {
                            that.get('alerts').showAlert('error');
                        });
                    });
                }).catch(function () {
                    that.set('err', that.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            }
        }
    });
});