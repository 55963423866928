define('uti-ui/routes/settings/partners', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseProfile')) {
                this.transitionTo('403');
            }
        },

        setupController: function setupController(controller, model) {
            controller.set('informer', this.get('informer.informer'));
            this.get('ajax').request('/payments/getPartners').then(function (data) {
                console.log(data);
                var paymentS = 0;
                var paymentL = 0;
                for (var i = data.profiles.length - 1; i >= 0; i--) {
                    paymentS += data.profiles[i].sum;
                    paymentL += data.profiles[i].pays;
                }
                controller.set('profiles', data.profiles);
                controller.set('paymentS', paymentS);
                controller.set('paymentL', paymentL);
            });
        }

    });
});