define('uti-ui/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
			console.log(snapshot, json, key, attributes);
			var c = snapshot.changedAttributes()[key];
			if (!!c || snapshot.record.get('isNew') && snapshot.record.get(key) || key === 'logs' || key === 'options' || key === 'images' || key === 'spPriceArr' || key === 'transactions') this._super(snapshot, json, key, attributes);
		},
		serialize: function serialize(snapshot, options) {
			var json = this._super.apply(this, arguments);
			if (snapshot.id) json.id = snapshot.id;
			return json;
		}
	});
});