define('uti-ui/controllers/modals/left-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		actions: {
			save: function save(model, path) {
				var that = this;
				path = that.get('model.isNew') ? path : false;
				model.validate().then(function () {
					model.save().then(function () {
						that.get('alerts').showAlert('success');
						that.send('removeModal', path);
					}).catch(function () {
						that.get('alerts').showAlert('error');
					});
				}).catch(function () {
					that.get('alerts').showAlert('warning');
					that.set('err', model.get('errors'));
				});
			},
			deleteOutlet: function deleteOutlet(model, path) {
				var that = this;
				Ember.$.confirm({
					title: '<i class="fa fa-trash alertIcon"></i> ' + that.get('i18n').t('toast.deleteT').string,
					content: that.get('i18n').t('toast.delete').string,
					confirm: function confirm() {
						model.set('isDelete', true);
						model.save().then(function () {
							that.send('removeModal', path);
						});
					}
				});
			}
		}
	});
});