define('uti-ui/controllers/settings/questrooms/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        appController: Ember.inject.controller('application'),
        informer: Ember.computed.reads('appController.informer'),

        sortedRooms: Ember.computed('model.@each', function () {
            return this.get('model').sortBy('position');
        }),

        actions: {
            moved: function moved(person, oldIndex, newIndex) {
                var content = this.get('sortedRooms').toArray();
                content.removeObject(person);
                content.insertAt(newIndex, person);
                content.forEach(function (i, index) {
                    i.set('position', $('*[data-id="' + i.get('id') + '"]').parent('li').index());
                    i.save();
                });
            }
        }
    });
});