define('uti-ui/routes/finance', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canDeleteTransactions')) {
                this.transitionTo('finance.salary');
            }
        },

        actions: {
            custom: function custom(model) {
                var i = model.get('outlet');
                var m = model.get('content') ? model.get('content') : model;
                this.send('showModal', i, m, '');
            },
            ok: function ok(model, type) {
                var that = this;
                that.controllerFor('finance').set('refresh', true);
                Ember.run.next(function () {
                    model.save();
                    that.controllerFor('finance').set('refresh', false);
                });
            }
        },

        model: function model() {
            return Ember.A([{ label: '', valuePath: 'type', width: '35px', cellComponent: 'tables/belongsTo-cell-cust' }, { label: 'Дата', valuePath: 'createdAt', cellComponent: 'tables/date-cell' }, { label: 'Назначение', width: '180px', sortable: false, valuePath: 'model', cellComponent: 'tables/custom-action' }, { label: 'Тип', valuePath: 'type', cellComponent: 'tables/belongsTo-cell' }, { label: 'Агент', sortable: false, cellComponent: 'tables/chose-agent' }, { label: 'Касса', valuePath: 'cashbox', cellComponent: 'tables/belongsTo-cell' }, { label: 'Сумма', valuePath: 'sum' }, { label: 'Автор', valuePath: 'author' }, { label: 'Коммент', valuePath: 'comment' }, { label: '', cellComponent: 'tables/destroy-button', width: '40px' }]);
        },

        setupController: function setupController(controller, model) {
            controller.set('session', this.get('session'));
            controller.set('model', model);
        }
    });
});