define('uti-ui/components/finance/finance-window', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberValidations.default, {

        validations: {
            'model.sum': { presence: true },
            'model.type': { belongs: true },
            'model.cashbox': { belongs: true }
        },

        informer: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var store = this.get('store');
            var _this = this;
            store.findAll('financeitem', { reload: true }).then(function (type) {
                _this.set('items', type);
            });
        },

        actions: {
            ok: function ok() {
                var that = this;
                var model = this.get('model');
                var type = +model.get('type.type');
                var title, count;
                if (type === 0) {
                    title = this.get('i18n').t('qr.expen');
                    count = 'expenseCount';
                }
                if (type === 1) {
                    title = this.get('i18n').t('qr.inco');
                    count = 'receiptCount';
                }
                if (type === 2) {
                    title = this.get('i18n').t('qr.move');
                    count = 'movingCount';
                }
                this.validate().then(function () {
                    if (model.get('isNew')) {
                        model.set('title', title + ' денежных средств №' + (that.get('informer.informer.' + count) + 1));
                        model.set('constType', type);
                        that.get('informer').addCount(count);
                        model.set('author', that.get("session.data.authenticated.user.fullName"));
                    }
                    model.set('user', model.get('user.id'));
                    model.set('client', model.get('client.id'));
                    model.set('contractor', model.get('contractor.id'));
                    that.sendAction('ok', model);
                    that.$('.modal').modal('hide');
                }).catch(function () {
                    that.set('err', that.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            }
        },

        show: function () {
            this.$('.modal').modal('show').on('hidden.bs.modal', function () {
                this.get('model').rollbackAttributes();
                this.sendAction('removeModal');
            }.bind(this));
        }.on('didInsertElement')
    });
});