define('uti-ui/validators/many', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    call: function call() {
      if (this.model.get(this.property).get('length') == 0) {
        this.errors.pushObject(this.options.message);
      }
    }
  });
});