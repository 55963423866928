define('uti-ui/components/statistic/visits-pie', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        pieObserver: Ember.observer('model.series', function () {
            this.update();
        }),

        didInsertElement: function didInsertElement() {
            this.update();
        },
        update: function update() {
            var chart = new Chartist.Pie('#visits-pie' + this.get('model.id'), {
                series: this.get('model.series')
            }, {
                donut: true,
                showLabel: true,
                width: '250px',
                height: '250px',
                reverseData: false,
                labelPosition: 'outside',
                labelDirection: 'neutral',
                ignoreEmptyValues: true
            });
        }
    });
});