define('uti-ui/components/parts/day-schedule', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		store: Ember.inject.service(),
		ajax: Ember.inject.service(),

		sortBy: ['start'],
		sortedTimes: Ember.computed.sort('d.intervals', 'sortBy'),

		actions: {
			clean: function clean() {
				var _this = this;

				var arr = this.get('d.intervals').mapBy('id');
				this.get('ajax').request('/questrooms/removeSchedule', {
					method: 'POST',
					data: {
						intervals: arr
					}
				}).then(function (res) {
					return _this.set('d.intervals', []);
				}).catch(function (_ref) {
					var res = _ref.res,
					    jqXHR = _ref.jqXHR,
					    payload = _ref.payload;
					return alert('Что-то пошло не так!');
				});
			},
			destroy: function destroy(t) {
				this.get('d.intervals').removeObject(t);
				t.destroyRecord();
			},
			add: function add(t) {
				if (t) return this.sendAction('showModal', 'modals/questinterval', t, false);

				var d = this.get('store').createRecord('questroomdateinterval', {
					date: this.get('d.date'),
					room: this.get('roomId')
				});

				this.get('d.intervals').pushObject(d);
				this.sendAction('showModal', 'modals/questinterval', d, false);
			}
		}
	});
});