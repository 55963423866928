define('uti-ui/controllers/statistic', ['exports', 'ember-validations', 'uti-ui/config/environment'], function (exports, _emberValidations, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _Ember$Controller$ext;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Controller.extend(_emberValidations.default, (_Ember$Controller$ext = {

        informer: Ember.inject.service(),

        ajax: Ember.inject.service(),
        visitsStatuses: null,
        expense: null,
        visitsSource: null,
        rooms: null,
        financeItems: null,

        to: moment.utc().toDate(),

        from: Ember.computed('informer.informer', function () {
            return moment.utc(this.get('informer.informer.createdAt')).toDate();
        }),

        validations: {
            from: { presence: true },
            to: { presence: true }
        },

        revenueByRooms: Ember.computed('rooms', function () {
            var items = this.get('rooms');

            if (items) {
                var arr = [];
                for (var i = 0; i < items.length; i++) {
                    arr.push({
                        name: items[i].title,
                        value: +items[i].revenue
                    });
                }
                return { id: 'revenueByRooms', series: arr };
            } else {
                return false;
            }
        }),

        revenue: Ember.computed('financeItems', function () {
            var items = this.get('financeItems');

            if (items) {
                var arr = [];
                for (var i = 0; i < items.length; i++) {
                    if (items[i].type === 1) {
                        arr.push({
                            name: items[i].title + ' - ' + items[i].sum + 'р',
                            value: +items[i].sum
                        });
                    }
                }
                return { id: 'revenue', series: arr };
            } else {
                return false;
            }
        })

    }, _defineProperty(_Ember$Controller$ext, 'expense', Ember.computed('financeItems', function () {
        var items = this.get('financeItems');

        if (items) {
            var arr = [];
            for (var i = 0; i < items.length; i++) {
                if (items[i].type === 0) {
                    arr.push({
                        name: items[i].title + ' - ' + items[i].sum + 'р',
                        value: +items[i].sum
                    });
                }
            }
            return { id: 'expense', series: arr };
        } else {
            return false;
        }
    })), _defineProperty(_Ember$Controller$ext, 'statuses', Ember.computed('visitsStatuses', function () {
        var statuses = this.get('visitsStatuses');
        if (statuses) {
            var series = [{
                value: statuses[0],
                name: 'Не оформлен',
                className: 'status0'
            }, {
                value: statuses[1],
                name: 'Не оплачен',
                className: 'status1'
            }, {
                value: statuses[2],
                name: 'Отменен',
                className: 'status2'
            }, {
                value: statuses[3],
                name: 'Не пришли',
                className: 'status3'
            }, {
                value: statuses[4],
                name: 'Оплачен',
                className: 'status4'
            }, {
                value: statuses[5],
                name: 'Есть долг',
                className: 'status5'
            }, {
                value: statuses[10],
                name: 'Не подтвержден',
                className: 'status10'
            }];
            return { id: 'statuses', series: series };
        } else {
            return false;
        }
    })), _defineProperty(_Ember$Controller$ext, 'actions', {
        getSalary: function getSalary(f, t) {
            var that = this;

            var from = moment.utc(this.get('from')).startOf('day').format();
            var to = moment.utc(this.get('to')).endOf('day').format();
            var days = moment(to).diff(from, 'days');

            if (!from || !to) return;
            if (days < 10) {
                alert('Минимальный срок сбора статистики 10 дней.');
                return;
            }
            that.get('ajax').request('/statistics/getInfoForEachRoom?start=' + from + '&end=' + to).then(function (data) {
                that.set('rooms', data.eachRoom);
                that.set('financeItems', data.finance);
                that.set('fromType', data.fromType);
                that.set('visitsStatuses', data.visitsStatus);
                that.set('sources', { id: 'sources', series: data.visitsSource });
            });
        }
    }), _Ember$Controller$ext));
});