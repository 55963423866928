define('uti-ui/models/sms-report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        createdAt: _emberData.default.attr('string'),
        to: _emberData.default.attr('string'),
        text: _emberData.default.attr('string'),
        profile: _emberData.default.attr('string'),
        code: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        statusH: function () {
            var st = this.get('code');
            if (st == 100) return 'Отправлено';
            return 'Не отправлено';
        }.property('code'),
        status: function () {
            var st = this.get('code');
            if (st == 100) return 4;
            return 3;
        }.property('code'),
        formatDate: function () {
            return moment(this.get('createdAt')).locale('ru').calendar();
        }.property('createdAt')
    });
});