define('uti-ui/models/contractor', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    title: _emberData.default.attr('string'),
    inn: _emberData.default.attr('string'),
    ogrn: _emberData.default.attr('number'),
    telephone: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    adres: _emberData.default.attr('string'),
    kpp: _emberData.default.attr('number'),
    transactions: _emberData.default.hasMany('transaction', { async: true }),
    isDelete: _emberData.default.attr('boolean'),

    outlet: 'modals.left-modal',
    componentName: 'finance/contractors-form',

    validations: {
      title: { presence: true }
    }
  });
});