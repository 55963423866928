define('uti-ui/routes/users/edit', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		alerts: Ember.inject.service(),

		model: function model(params) {
			return this.store.findRecord('user', params.employee_id);
		},

		actions: {
			willTransition: function willTransition(transition) {
				var model = this.currentModel;
				this.get('alerts').transition(transition, model);
			}
		}
	});
});