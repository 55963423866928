define('uti-ui/routes/sms/sms-reports', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
		model: function model() {
			return Ember.A([{ label: '', width: '50px', valuePath: 'status', cellComponent: 'tables/table-status' }, { label: 'Дата', valuePath: 'formatDate' }, { label: 'Текст', valuePath: 'text' }, { label: 'Кому', valuePath: 'to' }, { label: 'Статус', valuePath: 'statusH' }, { label: 'Описание', valuePath: 'description' }]);
		}
	});
});