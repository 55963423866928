define('uti-ui/models/adr', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {

    city: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    bus: _emberData.default.attr('string'),
    metro: _emberData.default.attr('string'),
    level: _emberData.default.attr('string'),
    profile: _emberData.default.attr('string'),
    isDelete: _emberData.default.attr('boolean', { defaultValue: false }),
    comment: _emberData.default.attr('string'),

    fullAdres: function () {
      var str = '';
      return this.get('city') + ', ' + this.get('street');
    }.property('city', 'street'),

    validations: {
      city: { presence: true },
      street: { presence: true }
    }
  });
});