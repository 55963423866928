define('uti-ui/routes/support/chat/view', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator', 'uti-ui/config/environment'], function (exports, _authenticatedRouteMixin, _loadingIndicator, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        //session: Ember.inject.service(),
        informer: Ember.inject.service(),
        alerts: Ember.inject.service(),

        model: function model(params) {
            return this.store.findRecord('support-case', params.chat_id);
        },

        setupController: function setupController(controller, model) {
            var inf = this.get('informer.informer.support');
            model.get('messages').forEach(function (s) {
                if (s.get('supportInform')) {
                    s.set('supportInform', '');
                    s.save();
                    inf.removeObject(s);
                }
            });

            controller.set('model', model);
        },

        actions: {
            close: function close(scase) {
                scase.set('closed', true);
                scase.save();
                this.transitionTo('support.chat');
            }
        }
    });
});