define('uti-ui/components/settings/quest-form', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;
    exports.default = Ember.Component.extend({

        informer: Ember.inject.service(),
        ajax: Ember.inject.service(),
        store: Ember.inject.service(),

        date: moment.utc().startOf('week'),
        isLoad: false,
        levels: Ember.A(['Легкий', 'Средний', 'Сложный']),
        fear: Ember.A([0, 1, 2, 3, 4, 5]),
        typesM: Ember.A(['Эскейп квесты', 'Квесты в реальности', 'Перформансы', 'Экшн-квесты', 'Городские квесты', 'Морфеус', 'VR квесты', 'Квизы']),

        changeDate: Ember.observer('date', function () {
            this.editDateFunc();
        }),

        humanDate: Ember.computed('date', function () {
            var date = this.get('date');
            return date.format('DD MMMM') + ' - ' + date.clone().add(6, 'days').format('DD MMMM');
        }),

        editDateFunc: function editDateFunc() {
            var _this = this;

            var date = this.get('date'),
                id = this.get('model.id');
            this.get('store').query('questroomdateinterval', {
                room: id,
                date: {
                    '>=': date.format('YYYY-MM-DD'),
                    '<=': date.clone().add(6, 'days').format('YYYY-MM-DD')
                }
            }).then(function (d) {
                var arr = [];
                for (var i = 0; i < 7; i++) {
                    var dateH = date.clone().add(i, 'days').format('YYYY-MM-DD');
                    arr.push({
                        date: dateH,
                        day: date.clone().add(i, 'days').format('dd, DD MMM'),
                        intervals: d.filterBy('date', dateH)
                    });
                }
                _this.set('dates', arr);
                _this.set('isLoad', true);
            });
        },


        isPriceBtnDisabled: Ember.computed('spFrom', 'spTo', 'spDate', 'spPrice', function () {
            if (!this.get('spFrom') || !this.get('spTo') || !this.get('spDate') || !this.get('spPrice')) return false;else return true;
        }),

        didInsertElement: function didInsertElement() {
            var store = this.get('store'),
                that = this,
                mask = this.get('i18n').t('mask');

            //$(".phone").mask(mask.string);
            $(".time").mask("99:99");
            this.set('lang', this.get('i18n').t('lang').string);

            that.set('types', Ember.A(['%', that.i18n.t('h.currency.short')]));
            that.set('autoD', that.get('days.firstObject'));

            var rooms = this.get('store').peekAll('questroom');
            that.set('rooms', rooms.filterBy('isDelete', false).filterBy('isActive', true));
            that.set('tags', store.peekAll('tag'));
            that.editDateFunc();
        },

        actions: {
            changeWeek: function changeWeek(i) {
                var a = i ? 'add' : 'subtract',
                    date = this.get('date');
                this.set('date', moment.utc(date)[a](1, 'weeks'));
            },
            getCropper: function getCropper() {
                var store = this.get('store');
                var that = this;
                var img = store.createRecord('imag', {
                    questroom: that.get('model.id')
                });
                that.get('model.images').pushObject(img);
                this.sendAction('getCropper', img, 'img', true, 'poster', 0.4);
            },
            doIntervals: function doIntervals(model) {
                var _this2 = this;

                var that = this,
                    s = this.get('autoS'),
                    po = this.get('autoPo'),
                    i = +this.get('autoI'),
                    d = this.get('autoD.date');

                if (!s || !po || !i || !d) return alert('Заполните все поля');
                this.set('isLoad', false);
                var sMin = s.split(':')[0] * 60 + +s.split(':')[1],
                    poMin = +po.split(':')[0] > 7 ? po.split(':')[0] * 60 + +po.split(':')[1] : (+po.split(':')[0] + 24) * 60 + +po.split(':')[1];

                var arr = [];
                for (var x = sMin; x <= poMin; x = x + i) {
                    var hours = Math.floor(x / 60) < 10 ? '0' + Math.floor(x / 60) : Math.floor(x / 60);
                    if (hours >= 24) {
                        d = moment.utc(d).add(1, 'days').format('YYYY-MM-DD');
                        hours = '0' + (hours - 24);
                    }
                    var a = x % 60;
                    var minuts = a == 0 ? a + '0' : a < 10 ? '0' + a : a;

                    arr.push({
                        price: that.get('autoP'),
                        start: hours + ':' + minuts,
                        date: d,
                        room: model.get('id')
                    });
                }
                that.get('ajax').request('/questrooms/saveSchedule', {
                    method: 'POST',
                    data: {
                        intervals: arr
                    }
                }).then(function (res) {
                    return that.editDateFunc();
                }).catch(function (_ref) {
                    var res = _ref.res,
                        jqXHR = _ref.jqXHR,
                        payload = _ref.payload;
                    return _this2.get('alerts').showAlert('error');
                });
            },
            copySchedule: function copySchedule() {
                var _this3 = this;

                this.get('ajax').request('/questrooms/copySchedule?date=' + this.get('dates')[0].date + '&room=' + this.get('model.id')).then(function () {
                    alert('Done!');
                    _this3.send('changeWeek', true);
                }).catch(function (_ref2) {
                    var res = _ref2.res,
                        jqXHR = _ref2.jqXHR,
                        payload = _ref2.payload;
                    return _this3.get('alerts').showAlert('error');
                });
            },


            save: function save(model) {
                var that = this;
                var isNew = model.get('isNew');

                model.set('city', model.get('adress.city'));
                model.validate().then(function () {
                    model.save().then(function (m) {
                        that.get('alerts').showAlert('success');
                        if (!isNew) {
                            that.sendAction('action', 'settings.questrooms');
                        } else {
                            that.sendAction('action', 'settings.questrooms.edit', m);
                        }
                    }).catch(function (err) {
                        that.get('alerts').showAlert('error');
                    });
                }).catch(function () {
                    that.get('alerts').showAlert('warning');
                    that.set('err', model.get('errors'));
                });
            },

            showModal: function showModal(a, b, c) {
                this.sendAction('showModal', a, b, c);
            }
        }
    });
});