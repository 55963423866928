define('uti-ui/routes/settings/profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),
        alerts: Ember.inject.service(),
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseProfile')) {
                this.transitionTo('403');
            }
        },

        model: function model() {
            return this.get('informer.informer');
        },

        actions: {
            willTransition: function willTransition(transition) {
                var model = this.controllerFor('settings.profile').get('model');
                this.get('alerts').transition(transition, model);
            },

            redirect: function redirect() {
                this.transitionTo('visits.list');
            }
        }
    });
});