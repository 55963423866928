define('uti-ui/routes/visits', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseVisits')) {
                this.transitionTo('login');
            }
        },

        actions: {}
    });
});