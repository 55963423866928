define('uti-ui/controllers/settings/questrooms/edit', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		bclass: Ember.computed('model.isActive', function () {
			return this.get('model.isActive') ? 'btn-danger' : 'btn-success';
		}),
		icon: Ember.computed('model.isActive', function () {
			return this.get('model.isActive') ? 'ban' : 'star';
		}),
		text: Ember.computed('model.isActive', function () {
			return this.get('model.isActive') ? this.get('i18n').t('qr.deactivate') : this.get('i18n').t('qr.activate');
		}),

		actions: {
			activate: function activate(model) {
				var that = this;
				model.validate().then(function () {
					that.transitionToRoute('settings.questrooms.index');
					model.toggleProperty('isActive');
					model.save().then(function () {
						that.get('alerts').showAlert('success');
					}).catch(function () {
						that.get('alerts').showAlert('error');
					});
				}).catch(function () {
					that.get('alerts').showAlert('warning');
					that.set('err', model.get('errors'));
				});
			}
		}
	});
});