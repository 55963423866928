define('uti-ui/routes/settings/questrooms/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        alerts: Ember.inject.service(),

        model: function model(params) {
            return this.store.findRecord('questroom', params.room_id);
        },

        actions: {
            willTransition: function willTransition(transition) {
                var model = this.currentModel;
                this.get('alerts').transition(transition, model);
            }
        }
    });
});