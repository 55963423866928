define('uti-ui/routes/settings/subscription/payments', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		model: function model() {
			return Ember.A([{ label: 'Дата', valuePath: 'formatDate' }, { label: 'Сумма', valuePath: 'amount' }, { label: 'Кол-во ключей', valuePath: 'keys' }, { label: 'Карта', valuePath: 'card' }, { label: 'Прошел?', valuePath: 'isConfirmed', cellComponent: 'tables/true-false' }]);
		}
	});
});