define('uti-ui/models/financeitem', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    isDelete: _emberData.default.attr('boolean'),
    isDefault: _emberData.default.attr('boolean'),

    isMoving: function () {
      return this.get('type') === 2 ? true : false;
    }.property('type'),

    typeHuman: function () {
      var t = this.get('type');
      if (t === 0) return this.get('i18n').t('qr.expen').string;
      if (t === 1) return this.get('i18n').t('qr.inco').string;
      if (t === 2) return this.get('i18n').t('qr.move').string;
    }.property('type'),

    validations: {
      title: { presence: true },
      type: { presence: true }
    }
  });
});