define('uti-ui/models/user', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {

    validations: {
      firstName: { presence: true },
      telephone: { presence: true }
    },

    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    confirmPassword: _emberData.default.attr('string'),
    img: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    vk: _emberData.default.attr('string'),
    profile: _emberData.default.attr('string'),
    schema: _emberData.default.belongsTo('salaryscheme', { async: true }),
    transactions: _emberData.default.belongsTo('transaction', { async: true }),
    telephone: _emberData.default.attr('phone'),
    specialty: _emberData.default.attr('string'),

    telegramToken: _emberData.default.attr('string', { defaultValue: 'появится после сохранения' }),
    telegramUser: _emberData.default.attr('string'),
    notifTelegram: _emberData.default.attr('boolean', { defaultValue: true }),
    notifSMS: _emberData.default.attr('boolean'),

    needSms: _emberData.default.attr('boolean'),

    canUse: _emberData.default.attr('boolean'),
    main: _emberData.default.attr('boolean', { defaultValue: false }),
    canUseVisits: _emberData.default.attr('boolean'),
    canUseClients: _emberData.default.attr('boolean'),
    canUseUsers: _emberData.default.attr('boolean'),
    canEditAccess: _emberData.default.attr('boolean'),
    canUseFinance: _emberData.default.attr('boolean'),
    canEditSalary: _emberData.default.attr('boolean'),
    canDeleteTransactions: _emberData.default.attr('boolean'),
    canUseStatistic: _emberData.default.attr('boolean'),
    canUseSms: _emberData.default.attr('boolean'),
    canUseProfile: _emberData.default.attr('boolean'),

    rooms: _emberData.default.hasMany('questroom', { async: true }),
    users: _emberData.default.hasMany('user', { async: true }),
    cashboxes: _emberData.default.hasMany('cashbox', { async: true }),
    roomsIds: _emberData.default.attr('string'), //?
    cashIds: _emberData.default.attr('string'), //?
    usersIds: _emberData.default.attr('string'), //?

    allRooms: _emberData.default.attr('boolean', { defaultValue: true }),
    allUsers: _emberData.default.attr('boolean'),
    allCashboxes: _emberData.default.attr('boolean'),

    regType: _emberData.default.attr('string', { defaultValue: 'client' }),
    isDelete: _emberData.default.attr('boolean'),
    //inside: DS.attr('boolean', {defaultValue: true}),

    title: function () {
      var ln = this.get('lastName') ? this.get('lastName') : '';
      return this.get('firstName') + ' ' + ln;
    }.property('firstName', 'lastName'),

    outlet: function () {
      return 'modals.user';
    }.property()
  });
});