define('uti-ui/routes/settings/discounts/promocodes', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
    model: function model() {
      return Ember.A([{ label: this.get('i18n').t('l.title'), valuePath: 'title' }, { label: this.get('i18n').t('w.promocode'), valuePath: 'code' }, { label: this.get('i18n').t('l.discount'), valuePath: 'discount', width: '70px' }, { label: '', valuePath: 'type', width: '50px' }, { label: this.get('i18n').t('lll.do'), valuePath: 'date', cellComponent: 'tables/date-simple' }, { label: this.get('i18n').t('w.once'), valuePath: 'isSingle', cellComponent: 'tables/true-false' }, { label: this.get('i18n').t('lll.uch'), valuePath: 'isIncrease', cellComponent: 'tables/true-false' }, { label: this.get('i18n').t('lll.col'), cellComponent: 'tables/promocode-visits' }, { label: '', cellComponent: 'tables/table-link', width: '30px' }]);
    },


    actions: {
      newCertificate: function newCertificate() {
        var model = this.store.createRecord('promocode', {
          code: 'PR' + Math.floor(1000 + Math.random() * (9999999 + 1 - 1000))
        });
        this.send('showModal', 'modals/left-modal', model);
      },
      saveAnother: function saveAnother(model) {
        var m = this.store.createRecord('promocode', model.toJSON());
        m.set('code', 'PR' + Math.floor(1000 + Math.random() * (9999999 + 1 - 1000)));
        m.save();
      }
    }
  });
});