define('uti-ui/services/alerts', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
        },


        informer: Ember.inject.service(),
        session: Ember.inject.service(),
        store: Ember.inject.service(),
        i18n: Ember.inject.service(),

        showAlert: function showAlert(status, message) {
            message = message || this.get('i18n').t('toastr.' + status + '.p').string;
            toastr[status](message, this.get('i18n').t('toastr.' + status + '.d').string);
        },
        newKeys: function newKeys(c) {
            $('#keysVal').html(c);
            $('.alertWrap').removeClass('hide');
        },
        ajaxSend: function ajaxSend(root) {
            $.ajax({
                headers: {
                    "Authorization": "Bearer " + this.get("session.data.authenticated.access_token")
                },
                url: _environment.default.host + root,
                type: 'GET',
                success: function success(data) {
                    toastr.success(data);
                },
                error: function error(err) {
                    toastr.error(err);
                }
            });
        },
        conf: function conf() {
            $.confirm({
                title: 'Удаление элемента',
                content: 'Вы действительно хотите удалить этот визит?',
                confirm: function confirm() {
                    return true;
                }
            });
        },
        transition: function transition(_transition, model) {
            var that = this;
            if (model.get('hasDirtyAttributes')) {
                if (that.get('flag') == undefined) {
                    _transition.abort();
                    $.confirm({
                        title: '<i class="fa fa-floppy-o alertIcon"></i> ' + this.get('i18n').t('lll.ns').string,
                        content: this.get('i18n').t('lll.ns2').string,
                        confirm: function confirm() {
                            model.rollbackAttributes();
                            that.set('flag', 1);
                            _transition.retry();
                        }
                    });
                }
            }

            that.set('flag', undefined);
        }
    });
});