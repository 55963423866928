define("uti-ui/components/modal-window", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        didInsertElement: function didInsertElement() {
            //$(".phone").mask(this.get('i18n').t('mask').string);
            $(".time").mask("99:99");
            $('.setFocus').focus();
        },

        actions: {
            ok: function ok() {

                var that = this;
                var model = this.get('model');
                model.validate().then(function () {
                    model.save().then(function (res) {
                        that.get('alerts').showAlert('success');
                        that.$('.modal').modal('hide');
                        if (that.get('marker')) {
                            $('#calendar').fullCalendar('removeEvents', new Array(model.get('id')));
                            $('#calendar').fullCalendar('renderEvent', {
                                id: model.get('id'),
                                title: model.get('title'),
                                start: model.get('date'),
                                end: model.get('dateEnd'),
                                resourceId: model.get('room.id'),
                                allDay: model.get('allDay'),
                                backgroundColor: '#f1f1f1'
                            });
                        }
                        //  that.sendAction('ok', model); 
                    }).catch(function (err) {
                        var resp = err.errors ? err.errors[0].detail : 'Изменения не сохранены!';
                        that.get('alerts').showAlert('error', resp);
                    });
                }).catch(function () {
                    that.set('err', model.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            }
        },
        show: function () {
            this.$('.modal').modal().on('hidden.bs.modal', function () {
                this.get('model').rollbackAttributes();
                this.sendAction('removeModal');
            }.bind(this));
        }.on('didInsertElement')
    });
});