define('uti-ui/routes/login', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),
        currentPath: Ember.computed.alias('controllers.application.currentPath'),

        beforeModel: function beforeModel(transition) {
            var that = this;

            if (!transition.queryParams.email && that.get("session.data.authenticated.access_token")) {
                that.transitionTo('index');
            }
            if (transition.queryParams.email && transition.queryParams.password && transition.queryParams.token) {
                $.ajax({
                    headers: {
                        "Authorization": "Bearer " + that.get("session.data.authenticated.access_token")
                    },
                    url: _environment.default.host + '/profiles/checkConfirmation?token=' + transition.queryParams.token + '&email=' + transition.queryParams.email,
                    type: 'GET',
                    success: function success() {
                        that.get('session').authenticate('authenticator:oauth2', transition.queryParams.email, transition.queryParams.password).catch(function (reason) {
                            that.controllerFor('login').set('message', reason.error || reason);
                        });
                        that.transitionTo('index');
                    },
                    error: function error(XMLHttpRequest, textStatus, errorThrown) {
                        var message = JSON.parse(XMLHttpRequest.responseText);
                        that.set('message', message.message);
                        that.controllerFor('login').set('message', message.message);
                    }
                });
            }

            //вход через vk
            var str = window.location.href;
            var from = str.search('access_token=') + 13;
            var to = str.search('&exp');

            if (from !== -1 && to !== -1) {
                var newstr = str.substring(from, to);
                this.get('session').authenticate('authenticator:vk', newstr, 1).catch(function (reason) {
                    that.controllerFor('login').set('message', reason.message);
                });
            }
        }

    });
});