define('uti-ui/models/promocode', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    title: _emberData.default.attr('string'), //код сертификата
    code: _emberData.default.attr('string'), //код сертификата
    room: _emberData.default.belongsTo('questroom', { async: true }),
    discount: _emberData.default.attr('number', { defaultValue: 0 }), //скидка по коду
    type: _emberData.default.attr('string', { defaultValue: '%' }), //тип скидки
    isIncrease: _emberData.default.attr('boolean', { defaultValue: false }),
    isSingle: _emberData.default.attr('boolean'), //использован или нет
    date: _emberData.default.attr('date'),
    isActive: _emberData.default.attr('boolean', { defaultValue: true }),
    isDelete: _emberData.default.attr('boolean', { defaultValue: false }),
    visitsCount: _emberData.default.attr('number', { defaultValue: 0 }),
    lastVisit: _emberData.default.attr('string'),

    componentName: 'settings/promocode-form',

    validations: {
      discount: { presence: true },
      code: { presence: true }
    }
  });
});