define('uti-ui/routes/sms/sms-settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
		informer: Ember.inject.service(),
		alerts: Ember.inject.service(),

		model: function model() {
			return this.get('informer.informer');
		},

		setupController: function setupController(controller, model) {
			controller.set('model', model);
			this.store.findAll('user').then(function (u) {
				controller.set('users', u);
			});
		},

		actions: {
			save: function save(model) {
				model.save().then(function (res) {
					that.get('alerts').showAlert('success');
				}).catch(function (err) {
					that.get('alerts').showAlert('error');
				});
			},
			testBot: function testBot() {
				this.get('alerts').ajaxSend('/smsSends/testBot');
			}
		}
	});
});