define('uti-ui/models/salary', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _emberData.default.Model.extend({
      days: _emberData.default.attr('number'),
      games: _emberData.default.hasMany('visit', { async: true }),
      user: _emberData.default.belongsTo('user', { async: true }),
      fromGames: _emberData.default.attr('number'),
      fromGamesDis: _emberData.default.attr('number'),
      map: _emberData.default.belongsTo('salaryscheme', { async: true }),
      cashbox: _emberData.default.belongsTo('cashbox', { async: true }),
      transactions: _emberData.default.hasMany('transaction', { async: true }),
      payed: _emberData.default.attr('number', { defaultValue: 0 }),
      toPay: function () {
         return this.get('sum') - this.get('payed');
      }.property('sum', 'payed'),
      sum: function () {
         var map = this.get('map');
         var perMonth = map.get('perMonth');
         var perDay = map.get('perDay');
         var fromServ = map.get('fromServ');
         var fromServType = map.get('fromServType');
         var isDiscount = map.get('isDiscount');
         var toPay = 0;
         if (this.get('days') && perDay) {
            toPay += this.get('days') * perDay;
         }
         if (perMonth) {
            toPay += perMonth;
         }
         if (fromServ) {
            if (fromServType === 1) {
               toPay += isDiscount ? this.get('fromGamesDis') / 100 * fromServ : this.get('fromGames') / 100 * fromServ;
            }

            if (fromServType === 0) {
               toPay += this.get('games.length') * fromServ;
            }
         }

         return toPay;
      }.property('map.perMonth', 'transactions', 'fromGames', 'fromGamesDis')
   });
});