define('uti-ui/components/tables/certificate-visits', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		ajax: Ember.inject.service(),
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.get('ajax').request('certificates/getLast/' + this.get('row.id')).then(function (data) {
				_this.set('date', data.date ? moment.utc(data.date).format('DD.MM.YYYY') : '-');
			});
		}
	});
});