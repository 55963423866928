define('uti-ui/controllers/modals/calendar-visit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        init: function init() {
            var that = this;
            this.store.findAll('questroom').then(function (q) {
                that.set('rooms', q);
                that.set('from', moment(that.get('model.date')).utc().format('HH:mm'));
                that.set('model.dateEnd', moment(that.get('model.date')).utc().add(60, 'minutes').format());
            });

            //$(".phone").mask(this.get('i18n').t('mask').string);
        },


        actions: {
            destroy: function destroy(m) {
                var obj = [m.get('id')];

                m.destroyRecord().then(function () {
                    that.get('alerts').showAlert('success');
                    $('#calendar').fullCalendar('removeEvents', obj);
                    $('.modal').modal('hide');
                }).catch(function (err) {
                    that.get('alerts').showAlert('error');
                });
            },
            setDateStart: function setDateStart() {
                var to = this.get('from');
                this.set('model.date', moment(this.get('model.date')).utc().startOf('day').add(to.split(':')[0], 'hours').add(to.split(':')[1], 'minutes').format());
            },
            setDateEnd: function setDateEnd() {
                var to = this.get('model.to');
                this.set('model.dateEnd', moment(this.get('model.date')).utc().startOf('day').add(to.split(':')[0], 'hours').add(to.split(':')[1], 'minutes').format());
            }
        }

    });
});