define('uti-ui/routes/users/salary', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

		session: Ember.inject.service(),

		model: function model() {
			return this.store.findAll('cashbox');
		},
		setupController: function setupController(controller, model) {
			controller.set('cashboxes', model);
		}

	});
});