define('uti-ui/validators/belongs', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    call: function call() {
      if (Ember.isEmpty(this.model.get(this.property).get('content'))) {
        this.errors.pushObject(this.options.message);
      }
    }
  });
});