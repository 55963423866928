define('uti-ui/components/employee-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),
        session: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var _this = this;

            //Ember.$(".phone").mask(this.get('i18n').t('mask').string);

            var store = this.get('store');

            this.set('rooms', store.peekAll('questroom').filterBy('isDelete', false).filterBy('isActive', true));

            store.findAll('cashbox', {
                isDelete: false
            }).then(function (c) {
                _this.set('cashboxes', c);
            });

            this.set('employees', store.peekAll('user').filterBy('isDelete', false));

            var m = this.get('employee');
            if (m.get('isNew')) {
                m.set('img', '/assets/img/ava' + (this.get('employees.length') + 2) + '.png');
            }
        },

        actions: {

            delete: function _delete(model) {
                this.sendAction('delete', model, 'users.index');
            },

            updateUser: function updateUser(model) {
                var that = this;

                if (model.get('password') && model.get('password') !== model.get('confirmPassword')) {
                    alert('Пароли не совпадают!');
                    return false;
                }

                model.validate().then(function () {

                    if (model.get('cashboxes')) {
                        var cashIds = [];
                        model.get('cashboxes').forEach(function (c) {
                            cashIds.push(c.get('id'));
                        });
                    }

                    if (model.get('users')) {
                        var usersIds = [];
                        model.get('users').forEach(function (e) {
                            usersIds.push(e.get('id'));
                        });
                    }

                    if (model.get('rooms')) {
                        var roomsIds = [];
                        model.get('rooms').forEach(function (r) {
                            roomsIds.push(r.get('id'));
                        });
                    }

                    model.set('roomsIds', roomsIds);
                    model.set('usersIds', usersIds);
                    model.set('cashIds', cashIds);

                    if (Ember.isPresent(model.changedAttributes().canUse)) {
                        model.set('needSms', true);
                    }

                    model.save().then(function (data) {
                        that.sendAction('redirect', 'users.index');
                        that.get('alerts').showAlert('success');
                    }).catch(function (err) {
                        that.get('alerts').showAlert('error', err.errors[0].detail);
                    });
                }).catch(function () {
                    that.set('err', model.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            },

            showModal: function showModal(a, b, c) {
                this.sendAction('showModal', a, b, c);
            },
            getCropper: function getCropper(a, b, c, d) {
                var img = this.get('store').createRecord('imag');
                this.sendAction('getCropper', a, b, c, d);
            }
        }
    });
});