define('uti-ui/models/salaryscheme', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {

    title: _emberData.default.attr('string'),
    perMonth: _emberData.default.attr('number', { defaultValue: 0 }),
    perDay: _emberData.default.attr('number', { defaultValue: 0 }),
    fromServType: _emberData.default.attr('number', { defaultValue: 0 }),
    fromServ: _emberData.default.attr('number', { defaultValue: 0 }),
    isDiscount: _emberData.default.attr('boolean'),
    isDelete: _emberData.default.attr('boolean'),
    fromServTypeH: function () {
      var t = this.get('fromServType');
      if (t === 0) {
        return this.get('i18n').t('h.currency.short');
      }
      if (t === 1) {
        return '%';
      }
    }.property('fromServType'),
    fullTitle: function () {
      var cur = this.get('i18n').t('h.currency.short');
      var t = this.get('title') + ': ';
      var f = this.get('fromServ') ? this.get('fromServ') + this.get('fromServTypeH') + ' за игру,' : '';
      var d = this.get('isDiscount') ? ' учитывая скидку по сертиикату,' : ' без учета скидки,';
      var m = this.get('perMonth') ? ' оклад в месяц ' + this.get('perMonth') + cur : '';
      var v = this.get('perDay') ? ' за выход ' + this.get('perDay') + cur : '';
      return this.get('title') ? t + f + d + m + v : '';
    }.property('title'),

    validations: {
      title: { presence: true }
    }
  });
});