define('uti-ui/controllers/settings/subscription/new-pay', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        session: Ember.inject.service(),
        parrent: Ember.inject.controller('settings.subscription'),

        setInfo: function () {
            var keys = this.get('informer.keys'),
                rooms = this.get('roomsLength'),
                freeDays = this.get('informer.freeMonth') ? 30 : 7,
                usedDays = Math.ceil((new Date() - new Date(this.get('informer.createdAt'))) / (24 * 60 * 60 * 1000)),
                daysIncreace = freeDays - usedDays,
                days = daysIncreace > 0 ? Math.floor(keys / rooms) + daysIncreace : Math.floor(keys / rooms),
                date = moment().add(days, 'days').format('LL');
            this.get('parrent').set('date', date);
            this.set('days', days);
        }.observes('informer.keys'),

        setPrice: function () {
            var defaultPrice = this.get('i18n').t('price'),
                rooms = this.get('roomsLength'),
                period = this.get('period'),
                finalPrice = getPrice(defaultPrice, rooms, period);
            this.set('keys', 30 * period * rooms);
            this.set('sum', finalPrice);
        }.observes('period'),

        actions: {
            pay: function pay() {
                var that = this;
                var randomInteger = function randomInteger(min, max) {
                    return Math.round(min + Math.random() * (max - min));
                };
                var orderId = that.get("session.data.authenticated.user.profile") + randomInteger(11111, 99999);
                var count = that.get('keys');
                var amount = that.get('sum');

                var payFunc = {
                    ru: function ru(amount, orderId) {
                        var params = {
                            TerminalKey: "1506533702046",
                            Amount: amount * 100,
                            OrderId: orderId
                        };
                        doPay(params);
                    },

                    ua: function ua(amount, orderId) {
                        $('#LMI_MERCHANT_ID').val('4471');
                        $('#LMI_PAYMENT_AMOUNT').val(amount);
                        $('#LMI_PAYMENT_NO').val(orderId);
                        $('#LMI_PAYMENT_DESC').val('Кол-во ключей: ' + count);
                        $('#sendPayForm').trigger('click');
                    }
                };

                var newPay = this.store.createRecord('payment', {
                    transaction: orderId,
                    amount: amount,
                    currency: that.get('i18n').t('h.currency.short'),
                    keys: count,
                    type: 'keys',
                    rooms: that.get('roomsLength'),
                    period: that.get('period'),
                    isConfirmed: false
                });

                var paySistem = that.get('i18n').t('lang');

                newPay.save().then(function () {
                    payFunc[paySistem](amount, orderId);
                });
            }
        }
    });
});