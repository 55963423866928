define('uti-ui/models/allday', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
				'use strict';

				Object.defineProperty(exports, "__esModule", {
								value: true
				});
				exports.default = _emberData.default.Model.extend(_emberValidations.default, {
								title: _emberData.default.attr('string'),
								room: _emberData.default.belongsTo('questroom', { async: true }),
								date: _emberData.default.attr('string'),
								dateEnd: _emberData.default.attr('string'),
								to: _emberData.default.attr('string'),
								isDelete: _emberData.default.attr('boolean', { defaultValue: false }),

								formatDate: function () {
												return moment(this.get('date')).utc().format('L');
								}.property('date'),

								time: function () {
												return moment(this.get('date')).utc().format('HH:mm');
								}.property('date'),

								validations: {
												date: { presence: true },
												room: { belongs: true }
								}
				});
});