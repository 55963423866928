define('uti-ui/components/auto-address', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      searchCities: function searchCities(term) {
        if (term.length < 1) {
          return [];
        }
        var cities = Ember.A(this.get('i18n').t('cities').string);

        return cities.split(',').filter(function (i) {
          return i.toLowerCase().indexOf(term.toLowerCase()) !== -1 ? i : false;
        });
      }
    }
  });
});