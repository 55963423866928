define('uti-ui/components/schedule-form', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        session: Ember.inject.service(),
        store: Ember.inject.service(),

        start: '09:00',
        end: '18:00',

        didInsertElement: function didInsertElement() {
            var that = this;
            var store = this.get('store');

            $(".time").mask("99:99");

            $.ajax({
                url: _environment.default.host + '/schedules',
                // data: { signature: authHeader },
                type: "GET",
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', "Bearer " + that.get("session.data.authenticated.access_token"));
                },
                success: function success(data) {
                    $('#calendar').fullCalendar({
                        lang: 'ru',
                        defaultView: 'timelineMonth',
                        selectable: true,
                        contentHeight: 'auto',
                        header: {
                            left: 'title',
                            center: '',
                            right: 'prev,next'
                        },
                        slotWidth: 60,
                        slotLabelFormat: 'DD, dd',
                        resourceAreaWidth: '20%',
                        resourceLabelText: 'Сотрудники',

                        //// uncomment this line to hide the all-day slot
                        //allDaySlot: false,

                        resources: data.users,
                        events: data.dates,

                        eventClick: function eventClick(calEvent, jsEvent, view) {
                            store.findRecord('schedule', calEvent.id).then(function (i) {
                                $('#calendar').fullCalendar('removeEvents', calEvent.id);
                                i.destroyRecord().then(function (i) {
                                    that.get('alerts').showAlert('success');
                                }).catch(function (err) {
                                    that.get('alerts').showAlert('error');
                                });
                            });
                        },

                        eventRender: function eventRender(event, element, view) {
                            element.find('.fc-title').html(element.find('.fc-title').text());
                            $(element).each(function () {
                                $(this).attr('date-num', event.start.format('YYYY-MM-DD'));
                            });
                        },

                        resourceRender: function resourceRender(resourceObj, labelTds, bodyTds) {
                            labelTds.html(labelTds.text());
                        },

                        dayClick: function dayClick(date, jsEvent, view, resource) {
                            var s = that.get('start') || '10:00';
                            var e = that.get('end') || '20:00';
                            var start = moment(date.format() + ' UTC').utc().add(s.split(':')[0], 'hours').add(s.split(':')[1], 'minutes').format();
                            var end = moment(date.format() + ' UTC').utc().add(e.split(':')[0], 'hours').add(e.split(':')[1], 'minutes').format();

                            store.createRecord('schedule', {
                                user: resource.id,
                                date: date.format(),
                                start: s,
                                end: e
                            }).save().then(function (i) {
                                that.get('alerts').showAlert('success');
                                $('#calendar').fullCalendar('renderEvent', {
                                    start: start,
                                    end: end,
                                    resourceId: resource.id,
                                    id: i.id,
                                    title: 'c ' + s + '<br/>по ' + e
                                });
                            }).catch(function (err) {
                                that.get('alerts').showAlert('error');
                            });
                        }
                    });
                }
            });
        }
    });
});