define('uti-ui/routes/visits/list', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),

        model: function model() {
            return Ember.A([{ label: '', width: '50px', valuePath: 'short', cellComponent: 'tables/short-row' }, { label: this.get('i18n').t('t.date'), valuePath: 'date', cellComponent: 'tables/date-cell' }, { label: this.get('i18n').t('t.visit'), valuePath: 'id', cellComponent: 'tables/table-id' }, { label: this.get('i18n').t('t.room'), valuePath: 'room', cellComponent: 'tables/visit-room' }, { label: this.get('i18n').t('w.client'), valuePath: 'telephone', cellComponent: 'tables/visit-client' }, { label: this.get('i18n').t('w.employees'), valuePath: 'personal', sortable: false, cellComponent: 'tables/users-row' }, { label: this.get('i18n').t('t.sum'), valuePath: 'finalSum', width: '70px', cellComponent: 'tables/visit-sum' }, { label: this.get('i18n').t('editings.comment'), valuePath: 'comment' }, { label: this.get('i18n').t('qr.mainPhoto'), valuePath: 'img', cellComponent: 'tables/image-cell' }]
            /*{ label: '', cellComponent: 'tables/table-link', width: '60px' }*/
            );
        },

        actions: {
            goTo: function goTo(model) {
                this.transitionTo('visits.edit', model);
            },
            setTrue: function setTrue(model, text) {
                model.set(text, true);
                model.save();
            }
        }
    });
});