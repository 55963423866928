define('uti-ui/models/clienttag', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend(_emberValidations.default, {
		title: _emberData.default.attr('string'),
		color: _emberData.default.attr('string'),
		isDelete: _emberData.default.attr('boolean', { defaultValue: false }),

		//ОПТИМИЗИРОВАТЬ
		//clients: DS.hasMany('client', {async: true}),

		validations: {
			title: { presence: true }
		}
	});
});