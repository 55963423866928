define('uti-ui/routes/registration', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),
        currentPath: Ember.computed.alias('controllers.application.currentPath'),

        beforeModel: function beforeModel(transition) {
            var that = this;
            var str = window.location.href;
            var from = str.search('access_token=') + 13;
            var to = str.search('&exp');

            if (from !== -1 && to !== -1) {
                var newstr = str.substring(from, to);
                this.get('session').authenticate('authenticator:vk', newstr, 0).catch(function (reason) {
                    that.controllerFor('registration').set('message', reason.message);
                });
            }
        }
    });
});