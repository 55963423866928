define('uti-ui/controllers/registration', ['exports', 'ember-validations', 'uti-ui/config/environment'], function (exports, _emberValidations, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberValidations.default, {

        validations: {
            identification: { presence: true },
            isValid: { presence: true }
        },

        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        promocode: false,
        codeSecret: false,
        repeatSend: false,
        changeNumber: false,

        isVk: Ember.computed('i18n', function () {
            return this.get('i18n').t("lang").string === 'ru' ? true : false;
        }),

        actions: {
            toggleValue: function toggleValue(val) {
                this.toggleProperty(val);
            },
            checkToken: function checkToken() {
                var that = this;
                if (+this.get('codeSecret') === +this.get('smsToken')) {
                    that.send('registration');
                }
            },
            sendCode: function sendCode() {
                var that = this;
                var text = this.get('identification');
                that.set('repeatSend', false);

                that.get('ajax').raw('/users/sendSmsConfirmation?phone=' + text).then(function (_ref) {
                    var response = _ref.response;

                    that.set('codeSecret', response.code / 24);
                    that.set('changeNumber', true);
                }).catch(function (_ref2) {
                    var response = _ref2.response,
                        jqXHR = _ref2.jqXHR,
                        payload = _ref2.payload;

                    that.set('message', payload.message);
                });

                var time = 60;
                var duration = moment.duration(time * 1000, 'milliseconds');
                that.set('showTimer', true);

                setInterval(function () {
                    duration = moment.duration(duration.asMilliseconds() - 1000, 'milliseconds');
                    that.set('timer', moment(duration.asMilliseconds()).format('mm:ss'));
                }, 1000);

                Ember.run.later(function () {
                    that.set('showTimer', false);
                    that.set('repeatSend', true);
                }, 60000);
            },
            emailOrTelephone: function emailOrTelephone() {
                var that = this;
                var text = this.get('identification');
                this.validate().then(function () {
                    if (text.indexOf('@') !== -1) {
                        that.send('registration'); //если вели Email
                    } else {
                        that.send('sendCode'); //если ввели телефон
                    }
                }).catch(function () {
                    that.set('err', that.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            },
            registration: function registration() {
                var that = this;
                var data = {
                    identification: that.get('identification'),
                    timeZone: new Date().getTimezoneOffset(),
                    regType: 'client'
                };

                if (!!that.get('promoCode')) data.promoCode = that.get('promoCode');

                that.get('ajax').raw('/users', {
                    method: 'POST',
                    data: data
                }).then(function (_ref3) {
                    var response = _ref3.response;

                    that.get('session').authenticate('authenticator:oauth2', response.identification, response.password, [], {
                        'Accept-Language': that.get('i18n').t("lang")
                    }).catch(function (reason) {
                        that.set('message', reason.message);
                    });
                }).catch(function (_ref4) {
                    var response = _ref4.response,
                        jqXHR = _ref4.jqXHR,
                        payload = _ref4.payload;

                    that.set('message', payload.message);
                });
            }
        }
    });
});