define('uti-ui/components/search/finance-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),

        status: Ember.A([{
            title: 'Расход',
            id: 0
        }, {
            title: 'Приход',
            id: 1

        }, {
            title: 'Перемещение',
            id: 2
        }]),

        didInsertElement: function didInsertElement() {
            var status = this.get('st');
            if (status) $('.statusf' + status).parent().addClass('active');
            var store = this.get('store');
            var _this = this;
            store.findAll('financeitem', { reload: true }).then(function (d) {
                _this.set('items', d);
            });
        },

        actions: {
            setStatus: function setStatus(m) {
                this.set('st', m.id);
                $('.status').removeClass('active');
                $('.statusf' + this.get('st')).parent().addClass('active');
            },
            hide: function hide() {
                this.set('isShowSearch', false);
            },
            searchClient: function searchClient(term) {
                if (term.length < 2) {
                    return [];
                }
                var store = this.get('store');
                return store.query('client', {
                    or: [{ 'title': { "contains": term } }, { 'telephone': { "contains": term } }, { 'email': { "contains": term } }]
                });
            },


            //поиск по контрагентам
            searchContractor: function searchContractor(term) {
                if (term.length < 2) {
                    return [];
                }
                var store = this.get('store');
                return store.query('contractor', {
                    or: [{ 'title': { "contains": term } }, { 'inn': { "contains": term } }]
                });
            },
            showResults: function showResults() {
                this.sendAction('findQueries');
            }
        }
    });
});