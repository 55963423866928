define('uti-ui/components/selects/quest-options', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),

        actions: {
            doOption: function doOption() {
                this.get('room.options').pushObject({
                    title: this.get('optionTitle'),
                    margin: this.get('optionMargin'),
                    comment: this.get('optionComment')
                });
                this.setProperties({
                    optionTitle: null,
                    optionMargin: null,
                    optionComment: null
                });
            },
            destroyInterval: function destroyInterval(i) {
                this.get('room.options').removeObject(i);
            }
        }
    });
});