define('uti-ui/routes/settings/integration/agregators', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        informer: Ember.inject.service(),

        setupController: function setupController(controller, model) {
            controller.set('model', this.get('informer.informer'));
        }

    });
});