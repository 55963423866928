define('uti-ui/components/tables/promocode-visits', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		ajax: Ember.inject.service(),
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.get('ajax').request('promocodes/getLast/' + this.get('row.id')).then(function (data) {
				_this.set('count', data);
			});
		}
	});
});