define('uti-ui/routes/settings', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
      session: Ember.inject.service(),

      afterModel: function afterModel() {
         if (!this.get('session.data.authenticated.access.canUseProfile')) {
            this.transitionTo('login');
         }
      }

   });
});