define('uti-ui/validators/time', ['exports', 'ember-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    call: function call() {
      if (this.model.get(this.property)) {
        var obj = this.model.get(this.property).split(':');
        if (obj[0] > 23 || obj[1] > 59 || !$.isNumeric(obj[1]) || !$.isNumeric(obj[0])) {
          this.errors.pushObject(this.options.message);
        }
      }
    }
  });
});