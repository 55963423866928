define('uti-ui/components/search/clients-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),
        currentTags: Ember.A(),

        types: Ember.A([{
            title: 'Всем клиентам',
            type: 0
        }, {
            title: 'Были на квесте X',
            type: 1
        }, {
            title: 'Не были на квесте X',
            type: 2
        }]),

        actions: {
            hide: function hide() {
                this.set('isShowSearch', false);
            },
            showResults: function showResults() {
                this.sendAction('findQueries');
            }
        }
    });
});