define('uti-ui/models/questroom', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    created_at: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    shortDescription: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    legend: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    maxDays: _emberData.default.attr('number', { defaultValue: 12 }),
    position: _emberData.default.attr('number', { defaultValue: 0 }),
    minTime: _emberData.default.attr('number', { defaultValue: 2 }),
    img: _emberData.default.attr('string'),
    getApi: _emberData.default.attr('string'),
    postApi: _emberData.default.attr('string'),
    apiType: _emberData.default.attr('string'),
    apiKey: _emberData.default.attr('string'),
    tags: _emberData.default.hasMany('tag', { async: true }),

    // timeClickYa: DS.attr('string'),
    // timeClickGa: DS.attr('string'),
    orderListYa: _emberData.default.attr('string'),
    orderListGa: _emberData.default.attr('string'),
    orderSingleYa: _emberData.default.attr('string'),
    orderSingleGa: _emberData.default.attr('string'),
    orderAllYa: _emberData.default.attr('string'),
    orderAllGa: _emberData.default.attr('string'),

    //по агрегатору
    discountOrders: _emberData.default.attr('number'),
    discountPercents: _emberData.default.attr('number'),
    isDiscount: _emberData.default.attr('boolean'),
    isAgregator: _emberData.default.attr('boolean', { defaultValue: true }),

    playersMin: _emberData.default.attr('number', { defaultValue: 2 }),
    playersMax: _emberData.default.attr('number', { defaultValue: 5 }),
    playersMaxi: _emberData.default.attr('number'),

    rooms: _emberData.default.hasMany('questroom', { async: true }),
    options: _emberData.default.attr('array', { defaultValue: [] }),

    isIntegrate: _emberData.default.attr('boolean', { defaultValue: false }),
    actors: _emberData.default.attr('boolean', { defaultValue: false }),
    isNewMarker: _emberData.default.attr('boolean', { defaultValue: false }),
    isActive: _emberData.default.attr('boolean', { defaultValue: true }),
    isIncrease: _emberData.default.attr('boolean', { defaultValue: false }),

    increase: _emberData.default.attr('number'),
    maxAge: _emberData.default.attr('number'),
    increaseType: _emberData.default.attr('string', { defaultValue: '%' }),
    telephone: _emberData.default.attr('string'),
    adress: _emberData.default.belongsTo('adr', { async: true }),

    level: _emberData.default.attr('string', { defaultValue: 'Средний' }),
    time: _emberData.default.attr('number', { defaultValue: 60 }),
    isDelete: _emberData.default.attr('boolean'),
    isHiden: _emberData.default.attr('boolean'),

    validations: {
      title: { presence: true },
      level: { presence: true },
      type: { presence: true },
      description: { presence: true },
      playersMin: { presence: true },
      playersMax: { presence: true },
      time: { presence: true },
      minTime: { presence: true },
      telephone: { presence: true },
      adress: { belongs: { message: 'aaa' } }
    },

    formatDate: function () {
      return moment(this.get('created_at')).utc().format('DD.MM.YYYY');
    }.property('created_at')
  });
});