define('uti-ui/models/transaction', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        createdAt: _emberData.default.attr('number'),
        title: _emberData.default.attr('string'),
        whoItIs: _emberData.default.attr('string'),
        comment: _emberData.default.attr('string'),
        type: _emberData.default.belongsTo('financeitem', { async: true }),
        constType: _emberData.default.attr('number'),
        contractor: _emberData.default.belongsTo('contractor', { async: true }),
        client: _emberData.default.attr('string'),
        user: _emberData.default.attr('string'),
        visit: _emberData.default.attr('string'),
        author: _emberData.default.attr('string'),
        isDelete: _emberData.default.attr('boolean', { defaultValue: false }),
        cashbox: _emberData.default.belongsTo('cashbox', { async: true }),
        cashboxExpense: _emberData.default.belongsTo('cashbox', { async: true }),
        sum: _emberData.default.attr('number'),

        outlet: function () {
            return this.get('visit.id') ? 'modals/visit' : 'modals/finance/receipt';
        }.property('visit', 'title'),

        isClient: function () {
            return this.get('whoItIs') == 'client';
        }.property('whoItIs'),

        isUser: function () {
            return this.get('whoItIs') == 'user';
        }.property('whoItIs'),

        isContractor: function () {
            return this.get('whoItIs') == 'contractor';
        }.property('whoItIs'),
        /*modelContractor: function() {
            if (this.get('contractor.id')){return this.get('contractor')};
            if (this.get('client.id')){return this.get('client')};
            if (this.get('user.id')){return this.get('user')};
        }.property('contractor,client,user'),*/
        model: function () {
            if (this.get('moving.id')) {
                return this.get('moving');
            };
            if (this.get('visit.id')) {
                return this.get('visit');
            };
            return this;
        }.property('moving', 'visit'),

        formatDate: function () {
            var date = this.get('createdAt');
            return moment(date).format('LLL');
        }.property('createdAt')
    });
});