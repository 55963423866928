define('uti-ui/routes/settings/discounts/index', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {
        model: function model() {
            return Ember.A([{ label: this.get('i18n').t('l.title'), valuePath: 'title' }, { label: this.get('i18n').t('lll.us'), valuePath: 'description' }, { label: this.get('i18n').t('l.discount'), valuePath: 'discount', width: '70px' }, { label: '', valuePath: 'type', width: '50px' }, { label: this.get('i18n').t('lll.uch'), valuePath: 'isIncrease', cellComponent: 'tables/true-false' }, { label: this.get('i18n').t('lll.la'), valuePath: 'lastVisit' }, { label: this.get('i18n').t('lll.col'), valuePath: 'visitsCount' }, { label: '', cellComponent: 'tables/table-link', width: '30px' }]);
        }
    });
});