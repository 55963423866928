define('uti-ui/routes/users/new', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        session: Ember.inject.service('session'),

        model: function model() {
            return this.store.createRecord('user', {
                profile: this.get('session.data.authenticated.user.profile')
            });
        }
    });
});