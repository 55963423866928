define('uti-ui/routes/visits/new', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

    alerts: Ember.inject.service(),
    informer: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {
      return this.store.createRecord('visit', {
        short: 'Adm',
        logs: [{
          img: this.get("session.data.authenticated.user.img"),
          log: moment().format('LLL') + ' ' + this.get("session.data.authenticated.user.fullName") + ' ' + this.get('i18n').t('new.crea')
        }],
        author: this.get("session.data.authenticated.user.fullName")
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        //var model = this.currentModel;
        //this.get('alerts').transition(transition, model)
      },
      redirect: function redirect() {
        this.transitionTo('visits.list');
      }
    }

  });
});