define('uti-ui/components/upload-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      document.getElementById('files').addEventListener('change', handleFileSelect, false);
      $('#files').trigger('click');
    },

    actions: {
      cancel: function cancel(model, root) {
        this.sendAction('action');
      },
      save: function save(model) {
        var _this = this;

        var that = this;
        var store = this.get('store');
        var u = store.createRecord('imag', {
          img: that.get('img')
        });

        u.save().then(function (res) {
          _this.set('model.img', res.get('img'));
          that.sendAction('action');
        });
      }
    }
  });
});