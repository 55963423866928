define('uti-ui/validators/phone', ['exports', 'ember-validations/validators/base', 'uti-ui/config/environment'], function (exports, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var length = void 0;

    if (_environment.default.lang === 'ru') length = 11;
    if (_environment.default.lang === 'ua') length = 12;
    if (_environment.default.lang === 'eu') length = 12;

    exports.default = _base.default.extend({
        call: function call() {
            var phone = this.model.get(this.property),
                formatPhone = phone ? phone.replace(/[^0-9]/gim, '') : '';

            if (formatPhone.length < length) {
                this.errors.pushObject(this.options.message);
            }
        }
    });
});