define('uti-ui/models/discount', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {

    discount: _emberData.default.attr('number', { defaultValue: 0 }), //скидка по коду
    title: _emberData.default.attr('string'), //за то или то
    description: _emberData.default.attr('string'), //сделай то и получи то
    type: _emberData.default.attr('string', { defaultValue: '%' }), //руб или процент
    isIncrease: _emberData.default.attr('boolean', { defaultValue: false }),
    isDelete: _emberData.default.attr('boolean', { defaultValue: false }),

    componentName: 'settings/discount-form',
    validations: {
      title: { presence: true },
      description: { presence: true },
      discount: { presence: true }
    }
  });
});