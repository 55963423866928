define('uti-ui/models/visit', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    createdAt: _emberData.default.attr('date'),
    author: _emberData.default.attr('string'),
    short: _emberData.default.attr('string', { defaultValue: 'NA' }), //код автора брони
    title: _emberData.default.attr('string'),
    title_room: _emberData.default.attr('string'),
    timeLeft: _emberData.default.attr('number'),
    players: _emberData.default.attr('number'),
    defaultSum: _emberData.default.attr('number'),
    sum: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    comand: _emberData.default.attr('string'),

    img: _emberData.default.attr('string'),
    telephone: _emberData.default.attr('phone'),
    clientName: _emberData.default.attr('string'),

    discount: _emberData.default.attr('number'),
    date: _emberData.default.attr('string'),
    dateEnd: _emberData.default.attr('string'),
    status: _emberData.default.attr('number', { defaultValue: 0 }),
    isAgregatorDiscount: _emberData.default.attr('boolean'),

    personal: _emberData.default.hasMany('user', { async: true }),

    client: _emberData.default.attr('string'),
    certificate: _emberData.default.belongsTo('certificate', { async: true }),
    promocode: _emberData.default.belongsTo('promocode', { async: true }),
    discountOption: _emberData.default.belongsTo('discount', { async: true }),
    room: _emberData.default.belongsTo('questroom', { async: true }),

    spPriceArr: _emberData.default.attr('array', { defaultValue: [] }),
    options: _emberData.default.attr('array', { defaultValue: [] }),
    logs: _emberData.default.attr('array', { defaultValue: [] }),
    transactions: _emberData.default.attr('array', { defaultValue: [] }),

    finalSum: function () {

      var players = +this.get('players') || 0,
          sp = this.get('spPriceArr') || [],
          defaultSum = +sp[players] || +this.get('defaultSum'),
          certificate = this.get('certificate.id') ? this.get('certificate') : this.get('promocode'),
          discountType = this.get('discountOption.type'),
          discountDis = this.get('discountOption.discount'),
          certificateType = certificate.get('type'),
          certificateDis = certificate.get('discount'),
          options = this.get('options.length') ? this.get('options') : false,
          discount = +this.get('discount') ? +this.get('discount') : 0,
          max = this.get('room.playersMax'),
          questDiscount = this.get('isAgregatorDiscount') ? 500 : 0,
          increase = +this.get('room.increase'),
          increaseType = this.get('room.increaseType');

      //вычисляем наценку за опции
      var visitIncrease = 0;
      if (options) {
        options.forEach(function (o) {
          visitIncrease += +o.margin;
        });
      }
      //вычисляем наценку за игроков
      if (players && increase && max && !sp[players]) {
        if (players > max) {
          visitIncrease += increaseType && increaseType == '%' ? (players - max) * (defaultSum / 100 * increase) : (players - max) * increase;
        }
      }

      var applyDiscount = function applyDiscount(val, type, count) {
        if (type === 1 || type !== '%') return val - count;
        return val - val / 100 * count;
      };
      if (certificateDis) {
        defaultSum = applyDiscount(defaultSum, certificateType, certificateDis);
        if (certificate.get('isIncrease') && (certificateType === 0 || certificateType === '%')) visitIncrease = applyDiscount(visitIncrease, certificateType, certificateDis);
      }

      if (discountDis) {
        defaultSum = applyDiscount(defaultSum, discountType, discountDis);
        if (this.get('discountOption.isIncrease') && discountType === '%') visitIncrease = applyDiscount(visitIncrease, discountType, discountDis);
      }

      var final = defaultSum + visitIncrease - discount;
      final = final > 0 ? final - questDiscount : 0;
      this.set('sum', final);
      return final;
    }.property('certificate.{discount,type}', 'players', 'discount', 'isAgregatorDiscount', 'defaultSum', 'discountOption.{discount,type}', 'options.@each.margin', 'promocode.{discount,type}'),

    payed: function () {
      var sum = 0;
      this.get('transactions').forEach(function (item) {
        sum += +item.sum;
      });
      return sum;
    }.property('transactions.[]'),

    setStatus: Ember.observer('payed', 'finalSum', function () {
      var status = this.get('status'),
          sum = +this.get('finalSum'),
          payed = +this.get('payed');
      if (status !== 0 && status !== 2 && status !== 3 && status !== 7 && status !== 10) {
        if (sum == payed) this.set('status', 4);
        if (payed == 0 && sum != 0) this.set('status', 1);
        if (sum > payed && payed != 0) this.set('status', 5);
        if (sum < payed) this.set('status', 6);
      }
    }),

    statusH: function () {
      var st = this.get('status');
      if (st === 0) return this.get('i18n').t('statuses.notOrder');
      if (st === 1) return this.get('i18n').t('statuses.notPayed');
      if (st === 2) return this.get('i18n').t('statuses.canceled');
      if (st === 3) return this.get('i18n').t('statuses.notCame');
      if (st === 4) return this.get('i18n').t('statuses.payed');
      if (st === 5) return this.get('i18n').t('statuses.halfPayed');
      if (st === 6) return this.get('i18n').t('statuses.muchPayed');
      if (st === 10) return this.get('i18n').t('statuses.notConfirm');
    }.property('status'),

    formatDate: function () {
      return moment(this.get('date')).utc().format('L');
    }.property('date'),

    formatCreated: function () {
      return moment(this.get('createdAt')).format('DD.MM.YYYY');
    }.property('createdAt'),

    time: function () {
      return moment(this.get('date')).utc().format('LT');
    }.property('date'),

    outlet: function () {
      return 'visits.show';
    }.property()

  });
});