define('uti-ui/routes/settings/subscription', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'uti-ui/mixins/loading-indicator'], function (exports, _authenticatedRouteMixin, _loadingIndicator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (!this.get('session.data.authenticated.access.canUseProfile')) {
                this.transitionTo('403');
            }
        }
    });
});