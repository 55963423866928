define('uti-ui/models/certificate', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_emberValidations.default, {
    discount: _emberData.default.attr('number', { defaultValue: 0 }), //
    isIncrease: _emberData.default.attr('boolean', { defaultValue: false }), //
    type: _emberData.default.attr('string', { defaultValue: '%' }), //тип скидки//
    isDelete: _emberData.default.attr('boolean', { defaultValue: false }),
    isActive: _emberData.default.attr('boolean', { defaultValue: true }),
    date: _emberData.default.attr('date'),
    code: _emberData.default.attr('string'), //код сертификата//
    room: _emberData.default.belongsTo('questroom', { async: true }),
    visit: _emberData.default.belongsTo('visit', { async: true }),

    componentName: 'settings/sertificate-form',
    validations: {
      code: { presence: true },
      discount: { presence: true }
    }
  });
});