define('uti-ui/routes/visits/calendar', ['exports', 'uti-ui/mixins/loading-indicator', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _loadingIndicator, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _loadingIndicator.default, {

    queryParams: {
      date: null,
      view: null
    },

    actions: {
      redirect: function redirect(path, id) {
        this.transitionTo(path, id);
      }
    }
  });
});