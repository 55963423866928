define('uti-ui/authenticators/torii', ['exports', 'ember-simple-auth/authenticators/torii'], function (exports, _torii) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = _torii.default.extend({
    torii: service('torii'),
    session: service('session'),
    authenticate: function authenticate(options) {
      var that = this;
      return this._super(options).then(function (data) {
        that.get('session').authenticate('authenticator:vk', data.authorizationCode, '');
      });
    }
  });
});