define('uti-ui/controllers/finance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        records: Ember.A(),
        length: 0,
        recordsSum: function () {
            var amount = 0;
            this.get('records').forEach(function (item) {
                amount += +item.get('sum');
            });
            return amount;
        }.property('records.@each.sum'),

        recordsLength: Ember.computed('records', function () {
            return this.get('records.length');
        }),

        params: function () {
            var _this = this;
            var status = _this.get('st') !== undefined ? _this.get('st') : false;
            var queries = {
                author: _this.get('user.title') || false,
                user: _this.get('emp.id') || false,
                room: _this.get('room.id') || false,
                client: _this.get('client.id') || false,
                contractor: _this.get('contractor.id') || false,
                cashbox: _this.get('cashbox.id') || false,
                type: _this.get('item.id') || false,
                from: _this.get('pStart') || false,
                to: _this.get('pEnd') || false,
                status: status
            };

            var query = {};
            var length = 0;

            for (var key in queries) {
                if (key !== 'to' && key !== 'from' && queries[key]) {
                    query[key] = queries[key];
                    length++;
                }
            }

            if (queries.to || queries.from) {
                query.createdAt = {};
                length++;
                if (queries.to) query.createdAt['<='] = moment(queries.to).utc().format();
                if (queries.from) query.createdAt['>='] = moment(queries.from).utc().format();
            }

            if (length === 0) {
                return false;
            } else {
                this.set('length', length);
                return query;
            }
        }.property('pStart,room,pEnd,user,st,client,emp,contractor,item,cashbox'),

        actions: {
            showSearch: function showSearch() {
                this.set('isShowSearch', true);
            },
            cancelQueries: function cancelQueries() {
                var that = this;
                that.set('pStart', false);
                that.set('room', false);
                that.set('pEnd', false);
                that.set('user', false);
                that.set('emp', false);
                that.set('st', false);
                that.set('cashbox', false);
                that.set('item', false);
                that.set('contractor', false);
                that.set('client', false);
                that.set('length', 0);
                that.set('refresh', true);
                Ember.run.next(function () {
                    that.set('refresh', false);
                });
            },
            findQueries: function findQueries() {
                var that = this;
                that.set('isShowSearch', false);
                that.set('refresh', true);
                that.get('recordsLength');
                Ember.run.next(function () {
                    that.set('refresh', false);
                });
            }
        }
    });
});