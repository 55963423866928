define('uti-ui/controllers/sms/sms-balance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        informer: Ember.inject.service(),
        session: Ember.inject.service(),

        setPrice: function () {
            var incereace = this.get('i18n').t('smsCost');
            this.set('sum', this.get('sms') * incereace);
        }.observes('sms'),

        sum: 0,
        sms: 0,

        actions: {
            pay: function pay() {
                var that = this;
                var randomInteger = function randomInteger(min, max) {
                    return Math.round(min + Math.random() * (max - min));
                };
                var orderId = that.get("session.data.authenticated.user.profile") + randomInteger(11111, 99999);
                var amount = that.get('sum');
                var count = that.get('sms');

                var payFunc = {
                    ru: function ru(amount, orderId) {
                        var params = {
                            TerminalKey: "1506533702046",
                            Amount: amount * 100,
                            OrderId: orderId
                        };
                        doPay(params);
                    },

                    ua: function ua(amount, orderId) {
                        $('#LMI_MERCHANT_ID').val('4471');
                        $('#LMI_PAYMENT_AMOUNT').val(amount);
                        $('#LMI_PAYMENT_NO').val(orderId);
                        $('#LMI_PAYMENT_DESC').val('Кол-во смс: ' + count);
                        $('#sendPayForm').trigger('click');
                    }
                };

                var newPay = this.store.createRecord('payment', {
                    transaction: orderId,
                    amount: amount,
                    currency: that.get('i18n').t('h.currency.short'),
                    sms: count,
                    type: 'sms',
                    isConfirmed: false
                });

                var paySistem = that.get('i18n').t('lang');

                newPay.save().then(function () {
                    payFunc[paySistem](amount, orderId);
                });
            }
        }
    });
});