define('uti-ui/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'uti-ui/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({

    serverTokenEndpoint: _environment.default.host + '/auths'
  });
});