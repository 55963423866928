define("uti-ui/components/finance/contractors-form", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		didInsertElement: function didInsertElement() {
			////$(".phone").mask(this.get('i18n').t('mask').string);
			$(".time").mask("99:99");
		},

		actions: {
			save: function save(model) {
				this.sendAction('save', model, 'settings/contractors');
			},
			deleteOutlet: function deleteOutlet(model) {
				this.sendAction('deleteOutlet', model, 'settings/contractors');
			}
		}
	});
});