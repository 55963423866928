define('uti-ui/controllers/login', ['exports', 'ember-validations'], function (exports, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_emberValidations.default, {

        session: Ember.inject.service(),

        validations: {
            identification: { presence: true },
            password: { presence: true }
        },

        isVk: Ember.computed('i18n', function () {
            return this.get('i18n').t("lang").string === 'ru' ? true : false;
        }),

        actions: {
            google: function google() {
                this.get('session').authenticate('authenticator:torii', 'google-oauth2');
            },
            authenticate: function authenticate() {
                var that = this;
                this.validate().then(function () {
                    var _that$getProperties = that.getProperties('identification', 'password'),
                        identification = _that$getProperties.identification,
                        password = _that$getProperties.password;

                    that.get('session').authenticate('authenticator:oauth2', identification, password, [], {
                        'Accept-Language': that.get('i18n').t("lang")
                    }).then(function () {
                        that.transitionTo('index');
                    }).catch(function (reason) {
                        that.set('message', reason.message);
                    });
                }).catch(function () {
                    that.set('err', that.get('errors'));
                    that.get('alerts').showAlert('warning');
                });
            }
        }
    });
});