define('uti-ui/components/tables/date-cell', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    computed = Ember.computed;
	exports.default = Ember.Component.extend({
		date: computed('value', function () {
			return moment(this.get('value')).utc().format('L') + ', ' + moment(this.get('value')).utc().format('dd') + ' в ' + moment(this.get('value')).utc().format('LT');
		})
	});
});