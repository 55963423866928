define('uti-ui/components/settings/promocode-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),

		didInsertElement: function didInsertElement() {
			var that = this;
			that.get('store').query('visit', {
				promocode: that.get('model.id')
			}).then(function (v) {
				that.set('visits', v);
			});
		},

		actions: {
			save: function save(model) {
				var that = this;
				var count = 1;
				model.validate().then(function () {
					if (model.isMany && model.count != null) count = model.count;
					model.set('title', model.get('title').toUpperCase());
					model.set('code', model.get('code').toUpperCase());

					for (var i = 1; i < count; i++) {
						that.sendAction('saveAnother', model);
					}
				}).then(function () {
					that.sendAction('save', model, 'settings/discounts/promocodes');
				}).catch(function () {
					that.get('alerts').showAlert('warning');
					that.set('err', model.get('errors'));
				});
			},
			deleteOutlet: function deleteOutlet(model) {
				this.sendAction('deleteOutlet', model, 'settings/discounts/promocodes');
			}
		}
	});
});