define('uti-ui/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    informer: Ember.computed.reads('appController.informer')
  });
});