define('uti-ui/controllers/settings/integration/agregators', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var list = {
    de: function de(id) {
      return [];
    },
    ua: function ua(id) {
      return [{
        author: 'агрегатор Мир квестов',
        short: 'MK',
        post: 'https://api.questadmin.com.ua/integrations/mq/' + id + '-MK',
        //   price: `https://api.questadmin.com.ua/integrations/checkPrice/${id}?format=MK&date=2016-07-20&time=20:30`,
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QuestRoom',
        short: 'QT',
        post: 'https://api.questadmin.com.ua/integrations/mq/' + id + '-QT',
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=qt'
      }, {
        author: 'агрегатор QuestGames',
        short: 'QG',
        post: '-',
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=questgames'
      }, {
        author: 'агрегатор Qimnata',
        short: 'QI',
        post: 'https://api.questadmin.com.ua/integrations/mq/' + id + '-QI',
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=qi'
      }, {
        author: 'агрегатор Questolog',
        short: 'QL',
        post: 'https://api.questadmin.com.ua/integrations/mq/' + id + '-QL',
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=ql'
      }, {
        author: 'агрегатор Q-Room',
        short: 'Qr',
        post: 'https://api.questadmin.com.ua/integrations/mq/' + id + '-Qr',
        get: 'https://api.questadmin.com.ua/integrations/getSchedule/' + id + '?format=qr'
      }];
    },
    ru: function ru(id) {
      return [{
        author: 'агрегатор Мир квестов',
        short: 'MK',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-MK',
        price: 'https://api.questadmin.ru/integrations/checkPrice/' + id + '?format=MK&date=2016-07-20&time=20:30',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Нора кролика',
        short: 'NK',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-NK',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QuestQuest',
        short: 'Qq',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Qq',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QuestCard',
        short: 'Qc',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Qc',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Квестики',
        short: 'Kv',
        post: 'https://api.questadmin.ru/integrations/kvestiki/' + id,
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=kvestiki'
      }, {
        author: 'агрегатор A-a-ax',
        short: 'Ax',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Ax',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Афиша',
        short: 'Af',
        cancel: 'https://api.questadmin.ru/integrations/cancelVisit?id=BOOCKING_ID',
        post: 'https://api.questadmin.ru/integrations/afisha/' + id,
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=afisha'
      }, {
        author: 'агрегатор КвестГид',
        short: 'Kg',
        post: 'https://api.questadmin.ru/integrations/qg/' + id,
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=Kg'
      }, {
        author: 'агрегатор QuestMe',
        short: 'QM',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QM',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Гильдия Квестов',
        short: 'GQ',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-GQ',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Sarquest',
        short: 'sQ',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-sQ',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор ExtraReality',
        short: 'ER',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-ER',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Q-Room',
        short: 'QR',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QR',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QuestFinder',
        short: 'QF',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QF',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Info Quests Краснодар',
        short: 'iq',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-iq',
        //   price: `https://api.questadmin.com.ua/integrations/checkPrice/${id}?format=MK&date=2016-07-20&time=20:30`,
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QR24',
        short: 'QR24',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QR24',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор ТопКвестов',
        short: 'TK',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-TK',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Хочу Онлайн',
        short: 'HO',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-HO',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор QuestCompass',
        short: 'QC',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QC',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Тест Квест',
        short: 'TQ',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-TQ',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Квестам.ру',
        short: 'Kp',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Kp',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Квестикс',
        short: 'KS',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-KS',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Qimnata',
        short: 'Qi',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Qi',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор EscapeMe',
        short: 'Em',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Em',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор EscapeTeams',
        short: 'Et',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Et',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Лига Квестов',
        short: 'Lk',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Lk',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор Quest Raiders',
        short: 'Qd',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Qd',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор СибКвест',
        short: 'Sb',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-Sb',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор КвестГуру',
        short: 'QGu',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-QGu',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }, {
        author: 'агрегатор LiveQuest',
        short: 'LQ',
        post: 'https://api.questadmin.ru/integrations/mq/' + id + '-LQ',
        get: 'https://api.questadmin.ru/integrations/getSchedule/' + id + '?format=mq'
      }];
    }
  };

  exports.default = Ember.Controller.extend({
    agregators: Ember.computed('room', function () {
      var that = this;
      that.set('agregator', null);
      return list[that.get('i18n').t('lang')](that.get('room.id'));
    })
  });
});