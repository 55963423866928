define('uti-ui/components/selects/power-rub', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		informer: Ember.inject.service('informer'),
		didInsertElement: function didInsertElement() {
			this.set('value', !this.get('model') ? this.get('informer.informer.currency') : '%');
			this.set('data', Ember.A([this.get('informer.informer.currency'), '%']));
		},
		actions: {
			change: function change(val) {
				this.set('model', val !== '%' ? 0 : 1);
				this.set('value', val);
			}
		}
	});
});