define('uti-ui/components/selects/power-cashbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.get('store').findAll('cashbox', { reload: true }).then(function (d) {
                _this.set('cashboxes', d);
            });
        }
    });
});