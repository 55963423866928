define('uti-ui/components/settings/profile-form', ['exports', 'uti-ui/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        store: Ember.inject.service(),
        session: Ember.inject.service(),
        currency: Ember.A(['₽', '$', '₴', '€']),
        addresses: Ember.A(),

        setTime: function setTime() {
            var cities = Ember.A(['Калининград (UTC+2)', 'Москва (UTC+3)', 'Самара (UTC+4)', 'Екатеринбург(UTC + 5)', 'Омск (UTC+6)', 'Красноярск (UTC+7)', 'Иркутск (UTC+8)', 'Якутск (UTC+9)', 'Владивосток (UTC+10)', 'Магадан (UTC+11)', 'Камчатка (UTC+12)']),
                that = this,
                tz = that.get('model.timeZone');

            var res = cities.map(function (c, i) {
                var k = (i + 2) * 60,
                    obj = {
                    time: moment.utc().add(k, 'minutes').format('LT'),
                    city: c,
                    value: -k
                };
                if (+tz === -k) that.set('currentZone', obj);
                return obj;
            });
            this.set('times', res);
        },


        didInsertElement: function didInsertElement() {
            $(".time").mask("99:99");
            this.set('addresses', this.get('store').peekAll('adr'));
            this.set('lang', this.get('i18n').t('lang').string);
            this.set('views', this.get('i18n').t('lll.days').string.split(','));
            this.set('link', 'https://signin.bookeo.com/?authappid=JXENXRAYAPL4&permissions=availability_r,bookings_rwd_all&state=' + this.get('model.id') + '&profile=' + this.get('model.id'));
            this.setTime();
        },

        actions: {
            addAddress: function addAddress() {
                var a = this.get('store').createRecord('adr');
                this.sendAction('showModal', 'modals/adres', a, false);
            },
            setCurrentTime: function setCurrentTime(t) {
                this.set('model.timeZone', t.value);
                this.set('currentZone', t);
            },
            showModal: function showModal(adr) {
                this.sendAction('showModal', 'modals/adres', adr, false);
            },
            destroy: function destroy(adr) {
                adr.destroyRecord().then(function () {
                    that.get('alerts').showAlert('success');
                }).catch(function (err) {
                    that.get('alerts').showAlert('error');
                });
            },
            save: function save(model) {
                var that = this;
                var startTime = model.get('startTime');
                var endTime = model.get('endTime');
                if (!startTime || !endTime) return that.get('alerts').showAlert('warning', that.get('i18n').t('lll.setTime').string);
                if (endTime <= startTime) model.set('endTime', +endTime.split(':')[0] + 24 + ':' + endTime.split(':')[1]);
                model.save().then(function (res) {
                    //that.sendAction('action');
                    that.get('alerts').showAlert('success');
                }).catch(function (err) {
                    that.get('alerts').showAlert('error');
                });
            }
        }
    });
});